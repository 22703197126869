import React from 'react';

const Exclamation = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.99805 0C3.68605 0 0.998047 2.688 0.998047 6C0.998047 9.312 3.68605 12 6.99805 12C10.31 12 12.998 9.312 12.998 6C12.998 2.688 10.31 0 6.99805 0ZM7.59805 8.4C7.59805 8.73137 7.32942 9 6.99805 9C6.66668 9 6.39805 8.73137 6.39805 8.4V6C6.39805 5.66863 6.66668 5.4 6.99805 5.4C7.32942 5.4 7.59805 5.66863 7.59805 6V8.4ZM7.59805 3.6C7.59805 3.93137 7.32942 4.2 6.99805 4.2C6.66668 4.2 6.39805 3.93137 6.39805 3.6C6.39805 3.26863 6.66668 3 6.99805 3C7.32942 3 7.59805 3.26863 7.59805 3.6Z"
                fill="#5FD2A2"
            />
        </svg>
    );
};

export default Exclamation;
