import clsx from 'clsx';
import toast from 'react-hot-toast';
import abiDecoder from 'abi-decoder';
import { useNavigate } from 'react-router-dom';
import { Contract, utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { SimpleButton } from '../reusable';
import { formatterFloat, getProperABI, parseMetamaskError } from '../../helpers/functions';
import PrivateSaleABI from '../../config/abi/PrivateSale.json';
import UnlimitedSaleABI from '../../config/abi/UnlimitedSale.json';
import TierBasedSaleABI from '../../config/abi/TierBasedSale.json';
import IntervalVestingABI from '../../config/abi/IntervalVesting.json';
import { Loading3Dot } from '../../assets/loading';
import { fetchWrapper } from '../../helpers/fetch-wrapper';
import {
    SALE_STATUS,
    SALE_TYPE,
    CONTROL_BUTTON_STATUS,
    KYC_STATUS,
    MEMBERSHIP_STATUS,
    SALE_GOING_STATUS,
} from '../../config/constants';
import { useGlobalContext } from '../../context/global/GlobalState';
import { useModalContext } from '../../context/modal/ModalState';
import { useTimerContext } from '../../context/timer/TimerState';
import { useSaleContext } from '../../context/sale/SaleState';
import useControlButtonDataStore from '../../stores/useControlButtonDataStore';

abiDecoder.addABI(IntervalVestingABI);
abiDecoder.addABI(PrivateSaleABI);
abiDecoder.addABI(UnlimitedSaleABI);
abiDecoder.addABI(TierBasedSaleABI);

const ControlButton = ({ clickHandler, isPreview = false, data: propData = null, ...rest }) => {
    const { data: storeData, setData: setStoreData } = useControlButtonDataStore();
    const [data, setEffectiveData] = useState(propData);

    useEffect(() => {
        if (propData === null && storeData !== null) {
            setEffectiveData(storeData);
        } else {
            setEffectiveData(propData);
        }
    }, [propData, storeData, setStoreData]);

    let navigate = useNavigate();
    const { currentTime } = useTimerContext();
    const { account, activeProvider, isValidWallet, kycStatus, personalInfo } = useGlobalContext();
    const { openModal, setModalData } = useModalContext();
    const { salePublic, getCurrentSaleStatus, controlButtonStatus, setControlButtonStatus } = useSaleContext();
    const [btnInfo, setBtnInfo] = useState({ firstText: '', secondText: '', clickable: false, disabled: false });

    const setRoundData = (step) => {
        if (!salePublic.isWhitelisted) {
            setBtnInfo({
                firstText: 'Address not whitelisted',
                secondText: "You didn't make this whitelist",
                clickable: false,
            });
            return;
        }

        if (step === 1) {
            if (
                +utils.formatUnits(salePublic.allocation, salePublic.paymentTokenDecimals) >
                +utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
            ) {
                setBtnInfo({
                    firstText:
                        controlButtonStatus === 0
                            ? 'Contribute'
                            : controlButtonStatus === CONTROL_BUTTON_STATUS.Approving
                            ? 'Approving'
                            : 'Contributing',
                    secondText: `Your remaining allocation: ${formatterFloat.format(
                        +utils.formatUnits(salePublic.allocation, salePublic.paymentTokenDecimals) -
                            +utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                    )} ${salePublic.paymentTokenSymbol ?? 'TBA'}`,
                    clickable: true,
                });
            } else {
                setBtnInfo({
                    firstText: `Contributed: ${formatterFloat.format(
                        utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                    )}`,
                    secondText: `Your remaining allocation: 0 ${salePublic.paymentTokenSymbol ?? 'TBA'}`,
                    clickable: false,
                });
            }
        } else if (step === 2 || step === 3) {
            setBtnInfo({
                firstText:
                    controlButtonStatus === 0
                        ? 'Contribute'
                        : controlButtonStatus === CONTROL_BUTTON_STATUS.Approving
                        ? 'Approving'
                        : 'Contributing',
                secondText: `Contributed: ${formatterFloat.format(
                    utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                )} ${salePublic.paymentTokenSymbol ?? 'TBA'}`,
                clickable: true,
            });
        } else {
            setBtnInfo({
                firstText: 'Sale is complete',
                secondText: `Wait until the token claiming starts`,
                clickable: false,
            });
        }
    };

    useEffect(() => {
        if (!data) {
            setBtnInfo({
                firstText: 'Loading...',
                secondText: '',
                clickable: false,
                disabled: true,
            });
            return;
        }
        if (!account || !isValidWallet) {
            setBtnInfo({
                firstText: 'Connect Wallet',
                secondText: 'You need to connect wallet',
                clickable: true,
                disabled: false,
            });
            return;
        }

        const currentSaleStatus = getCurrentSaleStatus(currentTime);
        if (isPreview) {
            const firstText = 'See more';
            let secondText;

            // isPreview property is for Sale list items
            // below comments line are wanted conditions but not yet implemented for backend
            // necessary implementation is required api/sale/list/private endpoint to return additional properties
            // Check Clickup task for more detail: https://app.clickup.com/t/866a5a8b2
            // switch (data.type) {
            //     case SALE_TYPE.TierBased:
            //         if (currentSaleStatus === SALE_STATUS.New) {
            //             if (kycStatus === KYC_STATUS.VERIFIED || kycStatus === KYC_STATUS.VERIFYING) {
            //                 secondText = 'Whitelist will open soon';
            //             } else {
            //                 secondText = 'Need verified KYC to participate';
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Open) {
            //             if (kycStatus !== KYC_STATUS.VERIFIED) {
            //                 if (personalInfo.membershipStatus === MEMBERSHIP_STATUS.Visitor) {
            //                     secondText = 'You need a Membership to participate';
            //                 } else {
            //                     if (!salePublic.isWhitelisted) {
            //                         secondText = 'You need to apply to the whitelist to participate';
            //                         if (
            //                             salePublic.isApplied &&
            //                             (personalInfo.membershipStatus === MEMBERSHIP_STATUS.Citizen ||
            //                                 personalInfo.membershipStatus === MEMBERSHIP_STATUS.Officer)
            //                         ) {
            //                             secondText = `You're participating in the whitelist lottery`;
            //                         }
            //                     } else if (
            //                         personalInfo.membershipStatus === MEMBERSHIP_STATUS.Ambassador ||
            //                         personalInfo.membershipStatus === MEMBERSHIP_STATUS.Senator
            //                     ) {
            //                         secondText = `You're whitelisted for ${personalInfo.membershipStatus} tier`;
            //                     }
            //                 }
            //             } else if (kycStatus !== KYC_STATUS.VERIFYING) {
            //                 secondText = 'Your KYC information is being reviewed';
            //             } else {
            //                 secondText = 'Need verified KYC to participate';
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Closed) {
            //             if (salePublic.isWhitelisted) {
            //                 secondText = `You're whitelisted for ${personalInfo.membershipStatus} tier`;
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Round1) {
            //             if (salePublic.isWhitelisted) {
            //                 const remainingValue =
            //                     parseFloat(utils.formatUnits(salePublic.allocation, salePublic.paymentTokenDecimals)) -
            //                     parseFloat(utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals));
            //                 secondText = `Your remaining allocation: ${remainingValue} ${
            //                     salePublic.paymentTokenSymbol ?? 'TBA'
            //                 }`;
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Round2) {
            //             if (salePublic.isWhitelisted) {
            //                 const currentTierAllocation = salePublic.tiers.find(
            //                     (tier) => tier.membershipTierId === currentTier.current.id
            //                 )?.allocation;

            //                 if (currentTierAllocation) {
            //                     const remainingValue =
            //                         parseFloat(
            //                             utils.formatUnits(currentTierAllocation, salePublic.paymentTokenDecimals)
            //                         ) -
            //                         parseFloat(
            //                             utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
            //                         );
            //                     secondText = `Your remaining allocation: ${remainingValue} ${
            //                         salePublic.paymentTokenSymbol ?? 'TBA'
            //                     }`;
            //                 }
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Round3) {
            //             if (salePublic.isWhitelisted) {
            //                 const remainingValue =
            //                     parseFloat(
            //                         utils.formatUnits(
            //                             salePublic.maximumRaiseAmountInPaymentToken,
            //                             salePublic.paymentTokenDecimals
            //                         )
            //                     ) -
            //                     parseFloat(utils.formatUnits(salePublic.totalApplied, salePublic.paymentTokenDecimals));
            //                 secondText = `Your remaining allocation: ${remainingValue} ${
            //                     salePublic.paymentTokenSymbol ?? 'TBA'
            //                 }`;
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (isSaleCompleted) {
            //             if (salePublic.isWhitelisted) {
            //                 if (salePublic.contribution > 0) {
            //                     if (new Date().getDate() - new Date(salePublic.claimingSchedule[0]).getDate() > 0) {
            //                         if (salePublic.claimableTokens - salePublic.claimedTokens > 0) {
            //                             secondText = 'Claim your unlocked tokens';
            //                         } else {
            //                             secondText = 'You have successfully participated!';
            //                         }
            //                     } else {
            //                         secondText = `Claiming opens at ${new Date(
            //                             salePublic.claimingSchedule[0]
            //                         ).toDateString()}`;
            //                     }
            //                 } else {
            //                     secondText = 'You have not participated';
            //                 }
            //             } else {
            //                 secondText = 'You have not participated';
            //             }
            //         }
            //         break;
            //     case SALE_TYPE.Unlimited:
            //         if (currentSaleStatus === SALE_STATUS.New) {
            //             if (kycStatus === KYC_STATUS.VERIFIED || kycStatus === KYC_STATUS.VERIFYING) {
            //                 secondText = 'Whitelist will open soon';
            //             } else {
            //                 secondText = 'Need verified KYC to participate';
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Open) {
            //             if (salePublic.isWhitelisted) {
            //                 secondText = `You're participating in the whitelist lottery`;
            //             } else {
            //                 if (kycStatus === KYC_STATUS.VERIFIED) {
            //                     secondText = `You need to apply to the whitelist to participate`;
            //                 } else if (kycStatus === KYC_STATUS.VERIFYING) {
            //                     secondText = `Your KYC information is being reviewed`;
            //                 } else {
            //                     secondText = `Need verified KYC to participate`;
            //                 }
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Closed) {
            //             if (salePublic.isWhitelisted) {
            //                 secondText = `You're whitelisted!`;
            //             } else if (salePublic.isApplied) {
            //                 secondText = `You're participating in the whitelist lottery`;
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Round1) {
            //             if (salePublic.isWhitelisted) {
            //                 const remainingValue =
            //                     parseFloat(utils.formatUnits(salePublic.allocation, salePublic.paymentTokenDecimals)) -
            //                     parseFloat(utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals));
            //                 secondText = `Your remaining allocation: ${remainingValue} ${
            //                     salePublic.paymentTokenSymbol ?? 'TBA'
            //                 }`;
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (currentSaleStatus === SALE_STATUS.Round2) {
            //             if (salePublic.isWhitelisted) {
            //                 const currentTierAllocation = salePublic.tiers.find(
            //                     (tier) => tier.membershipTierId === currentTier.current.id
            //                 )?.allocation;

            //                 if (currentTierAllocation) {
            //                     const remainingValue =
            //                         parseFloat(
            //                             utils.formatUnits(currentTierAllocation, salePublic.paymentTokenDecimals)
            //                         ) -
            //                         parseFloat(
            //                             utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
            //                         );
            //                     secondText = `Your remaining allocation: ${remainingValue} ${
            //                         salePublic.paymentTokenSymbol ?? 'TBA'
            //                     }`;
            //                 }
            //             } else {
            //                 secondText = `You didn't make this whitelist`;
            //             }
            //         } else if (isSaleCompleted) {
            //             if (salePublic.isWhitelisted) {
            //                 if (salePublic.contribution > 0) {
            //                     if (new Date().getDate() - new Date(salePublic.claimingSchedule[0]).getDate() > 0) {
            //                         if (salePublic.claimableTokens - salePublic.claimedTokens > 0) {
            //                             secondText = 'Claim your unlocked tokens';
            //                         } else {
            //                             secondText = 'You have successfully participated!';
            //                         }
            //                     } else {
            //                         secondText = `Claiming opens at ${new Date(
            //                             salePublic.claimingSchedule[0]
            //                         ).toDateString()}`;
            //                     }
            //                 } else {
            //                     secondText = 'You have not participated';
            //                 }
            //             } else {
            //                 secondText = 'You have not participated';
            //             }
            //         }
            //         break;
            //     case SALE_TYPE.Private:
            //         const saleStartDate = salePublic.rounds[0].startsAt;
            //         const isSaleStarted = new Date().getDate() - new Date(saleStartDate).getDate() > 0;
            //         if (!isSaleStarted) {
            //             secondText = `You're whitelisted!`;
            //         } else if (currentSaleStatus === SALE_STATUS.Round1) {
            //             const remainingValue =
            //                 parseFloat(utils.formatUnits(salePublic.allocation, salePublic.paymentTokenDecimals)) -
            //                 parseFloat(utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals));
            //             secondText = `Your remaining allocation: ${remainingValue} ${
            //                 salePublic.paymentTokenSymbol ?? 'TBA'
            //             }`;
            //         } else if (isSaleCompleted) {
            //             if (salePublic.isWhitelisted) {
            //                 if (salePublic.contribution > 0) {
            //                     if (new Date().getDate() - new Date(salePublic.claimingSchedule[0]).getDate() > 0) {
            //                         if (salePublic.claimableTokens - salePublic.claimedTokens > 0) {
            //                             secondText = 'Claim your unlocked tokens';
            //                         } else {
            //                             secondText = 'You have successfully participated!';
            //                         }
            //                     } else {
            //                         secondText = `Claiming opens at ${new Date(
            //                             salePublic.claimingSchedule[0]
            //                         ).toDateString()}`;
            //                     }
            //                 } else {
            //                     secondText = 'You have not participated';
            //                 }
            //             } else {
            //                 secondText = 'You have not participated';
            //             }
            //         }
            //         break;
            //     default:
            //         break;
            // }
            const isWhitelistEnded = new Date().getTime - new Date(data.whitelistEndsAt).getTime() > 0;

            switch (data.type) {
                case SALE_TYPE.TierBased:
                    if (data.status === SALE_GOING_STATUS.Upcoming) {
                        if (isWhitelistEnded) {
                            secondText = 'Sale starting soon';
                        } else {
                            secondText = 'Sale starting soon, get whitelisted now!';
                        }
                    } else if (data.status === SALE_GOING_STATUS.Ongoing) {
                        secondText = 'Sale is ongoing, contribute now!';
                    } else if (data.status === SALE_GOING_STATUS.Completed) {
                        secondText = 'Sale is completed, claim your tokens!';
                    }
                    break;
                case SALE_TYPE.Unlimited:
                    if (data.status === SALE_GOING_STATUS.Upcoming) {
                        if (isWhitelistEnded) {
                            secondText = 'Sale starting soon';
                        } else {
                            secondText = 'Sale starting soon, get whitelisted now!';
                        }
                    } else if (data.status === SALE_GOING_STATUS.Ongoing) {
                        secondText = 'Sale is ongoing, contribute now!';
                    } else if (data.status === SALE_GOING_STATUS.Completed) {
                        secondText = 'Sale is completed, claim your tokens!';
                    }
                    break;
                case SALE_TYPE.Private:
                    if (data.status === SALE_GOING_STATUS.Ongoing) {
                        secondText = 'Sale is ongoing, contribute now!';
                    } else if (data.status === SALE_GOING_STATUS.Completed) {
                        secondText = 'Sale is completed, claim your tokens!';
                    }
                    break;
                default:
                    break;
            }

            setBtnInfo({ firstText, secondText, clickable: true });
            return;
        }

        switch (data.type) {
            case SALE_TYPE.TierBased:
                switch (currentSaleStatus) {
                    case SALE_STATUS.New:
                        if (kycStatus === KYC_STATUS.VERIFIED || kycStatus === KYC_STATUS.VERIFYING) {
                            setBtnInfo({
                                firstText: 'Coming soon',
                                secondText: 'Whitelist will open soon',
                                clickable: false,
                            });
                        } else {
                            setBtnInfo({
                                firstText: 'Verify KYC',
                                secondText: 'Need verified KYC to participate',
                                clickable: true,
                            });
                        }
                        break;
                    case SALE_STATUS.Open:
                        if (kycStatus === KYC_STATUS.NONE || kycStatus === KYC_STATUS.UNVERIFIED) {
                            setBtnInfo({
                                firstText: 'Verify KYC',
                                secondText: 'Need verified KYC to participate',
                                clickable: true,
                            });
                            return;
                        } else if (kycStatus === KYC_STATUS.FAILED) {
                            setBtnInfo({
                                firstText: 'Resubmit KYC',
                                secondText: 'Your KYC verification failed',
                                clickable: true,
                            });
                            return;
                        } else if (kycStatus === KYC_STATUS.VERIFYING) {
                            setBtnInfo({
                                firstText: 'Verifying KYC...',
                                secondText: 'Your KYC information is being reviewed',
                                clickable: true,
                            });
                            return;
                        }

                        if (personalInfo.membershipStatus === MEMBERSHIP_STATUS.Visitor) {
                            setBtnInfo({
                                firstText: 'Become a Member',
                                secondText: 'You need a Membership to participate',
                                clickable: true,
                            });
                            return;
                        }

                        if (data.isApplied) {
                            if (
                                personalInfo.membershipStatus === MEMBERSHIP_STATUS.Senator ||
                                personalInfo.membershipStatus === MEMBERSHIP_STATUS.Ambassador
                            ) {
                                setBtnInfo({
                                    firstText: 'Address whitelisted',
                                    secondText: `You're whitelisted for ${personalInfo.membershipStatus} tier`,
                                    clickable: false,
                                });
                            } else {
                                setBtnInfo({
                                    firstText: 'Application submitted',
                                    secondText: "You're participating in the whitelist lottery",
                                    clickable: false,
                                });
                            }
                        } else {
                            setBtnInfo({
                                firstText: 'Apply for Whitelist',
                                secondText: 'You need to apply to the whitelist to participate',
                                clickable: true,
                            });
                        }
                        break;
                    case SALE_STATUS.Closed:
                        if (data.isWhitelisted) {
                            setBtnInfo({
                                firstText: 'Address whitelisted',
                                secondText: `You're whitelisted for ${personalInfo.membershipStatus} tier`,
                                clickable: false,
                            });
                        } else {
                            setBtnInfo({
                                firstText: 'Address not whitelisted',
                                secondText: `You didn't make this whitelist`,
                                clickable: false,
                            });
                        }
                        break;
                    case SALE_STATUS.Round1:
                        setRoundData(1);
                        break;
                    case SALE_STATUS.Round2:
                        setRoundData(2);
                        break;
                    case SALE_STATUS.Round3:
                        setRoundData(3);
                        break;
                    case SALE_STATUS.Round1End:
                    case SALE_STATUS.Round2End:
                    case SALE_STATUS.Round3End:
                        setRoundData(4);
                        break;
                    case SALE_STATUS.Claiming:
                    case SALE_STATUS.ClaimingEnds:
                        if (
                            +utils.formatUnits(salePublic.totalPurchasedTokens, salePublic.project.tokenDecimals) === 0
                        ) {
                            setBtnInfo({
                                firstText: 'Sale is complete',
                                secondText: 'You did not participate',
                                clickable: false,
                            });
                        } else {
                            if (
                                salePublic.totalPurchasedTokens === salePublic.claimedTokens ||
                                +utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals) === 0
                            ) {
                                setBtnInfo({
                                    firstText: `Claimed: ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimedTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    secondText: 'You have successfully participated!',
                                    clickable: false,
                                });
                            } else {
                                setBtnInfo({
                                    firstText: 'Claim',
                                    secondText: `Claim your unlocked ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    clickable: true,
                                });
                            }
                        }
                        break;
                    case SALE_STATUS.ClaimingRefund:
                        setBtnInfo({
                            firstText: 'Claim refund',
                            secondText: `Claim your refund: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            clickable: true,
                        });
                        break;
                    case SALE_STATUS.ClaimingRefundEnd:
                        setBtnInfo({
                            firstText: `You've claimed: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            secondText: 'Refund claimed',
                            clickable: false,
                        });
                        break;
                    default:
                        break;
                }
                break;
            case SALE_TYPE.Unlimited:
                switch (currentSaleStatus) {
                    case SALE_STATUS.New:
                        if (kycStatus === KYC_STATUS.VERIFIED || kycStatus === KYC_STATUS.VERIFYING) {
                            setBtnInfo({
                                firstText: 'Coming soon',
                                secondText: 'Whitelist will open soon',
                                clickable: false,
                            });
                        } else {
                            setBtnInfo({
                                firstText: 'Verify KYC',
                                secondText: 'Need verified KYC to participate',
                                clickable: true,
                            });
                        }
                        break;
                    case SALE_STATUS.Open:
                        if (kycStatus === KYC_STATUS.VERIFIED) {
                            if (personalInfo.membershipStatus === MEMBERSHIP_STATUS.Visitor) {
                                setBtnInfo({
                                    firstText: 'Become a Member',
                                    secondText: 'You need a Membership to participate',
                                    clickable: true,
                                });
                                return;
                            }

                            if (data.isApplied) {
                                if (
                                    personalInfo.membershipStatus === MEMBERSHIP_STATUS.Senator ||
                                    personalInfo.membershipStatus === MEMBERSHIP_STATUS.Ambassador
                                ) {
                                    setBtnInfo({
                                        firstText: 'Address whitelisted',
                                        secondText: `You're whitelisted for ${personalInfo.membershipStatus} tier`,
                                        clickable: false,
                                    });
                                } else {
                                    setBtnInfo({
                                        firstText: 'Application submitted',
                                        secondText: "You're participating in the whitelist lottery",
                                        clickable: false,
                                    });
                                }
                            } else {
                                setBtnInfo({
                                    firstText: 'Apply for Whitelist',
                                    secondText: 'You need to apply to the whitelist to participate',
                                    clickable: true,
                                });
                            }
                        } else if (kycStatus === KYC_STATUS.VERIFYING) {
                            setBtnInfo({
                                firstText: 'Verifying KYC...',
                                secondText: 'Your KYC information is being reviewed',
                                clickable: false,
                            });
                        } else {
                            setBtnInfo({
                                firstText: 'Verify KYC',
                                secondText: 'Need verified KYC to participate',
                                clickable: true,
                            });
                        }
                        break;
                    case SALE_STATUS.Closed:
                        if (data.isWhitelisted) {
                            setBtnInfo({
                                firstText: 'Address whitelisted',
                                secondText: `You're whitelisted!`,
                                clickable: false,
                            });
                        } else if (data.isApplied) {
                            setBtnInfo({
                                firstText: 'Application submitted',
                                secondText: `You're participating in the whitelist lottery`,
                                clickable: false,
                            });
                        } else {
                            setBtnInfo({
                                firstText: 'Address not whitelisted',
                                secondText: `You didn't make this whitelist`,
                                clickable: false,
                            });
                        }
                        break;
                    case SALE_STATUS.Round1:
                        setRoundData(1);
                        break;
                    case SALE_STATUS.Round2:
                        setRoundData(2);
                        break;
                    case SALE_STATUS.Round1End:
                    case SALE_STATUS.Round2End:
                        setRoundData(4);
                        break;
                    case SALE_STATUS.Claiming:
                    case SALE_STATUS.ClaimingEnds:
                        if (
                            +utils.formatUnits(salePublic.totalPurchasedTokens, salePublic.project.tokenDecimals) === 0
                        ) {
                            setBtnInfo({
                                firstText: 'Sale is complete',
                                secondText: 'You did not participate',
                                clickable: false,
                            });
                        } else {
                            if (
                                salePublic.totalPurchasedTokens === salePublic.claimedTokens ||
                                +utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals) === 0
                            ) {
                                setBtnInfo({
                                    firstText: `Claimed: ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimedTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    secondText: 'You have successfully participated!',
                                    clickable: false,
                                });
                            } else {
                                setBtnInfo({
                                    firstText: 'Claim',
                                    secondText: `Claim your unlocked ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    clickable: true,
                                });
                            }
                        }
                        break;
                    case SALE_STATUS.ClaimingRefund:
                        setBtnInfo({
                            firstText: 'Claim refund',
                            secondText: `Claim your refund: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            clickable: true,
                        });
                        break;
                    case SALE_STATUS.ClaimingRefundEnd:
                        setBtnInfo({
                            firstText: `You've claimed: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            secondText: 'Refund claimed',
                            clickable: false,
                        });
                        break;
                    default:
                        break;
                }
                break;
            case SALE_TYPE.Private:
                switch (currentSaleStatus) {
                    case SALE_STATUS.New:
                    case SALE_STATUS.Open:
                    case SALE_STATUS.Closed:
                        setBtnInfo({
                            firstText: `Address whitelisted`,
                            secondText: `You're whitelisted`,
                            clickable: false,
                        });
                        break;
                    case SALE_STATUS.Round1:
                        setRoundData(1);
                        break;
                    case SALE_STATUS.Round1End:
                        setRoundData(4);
                        break;
                    case SALE_STATUS.Claiming:
                    case SALE_STATUS.ClaimingEnds:
                        if (
                            +utils.formatUnits(salePublic.totalPurchasedTokens, salePublic.project.tokenDecimals) === 0
                        ) {
                            setBtnInfo({
                                firstText: 'Sale is complete',
                                secondText: 'You did not participate',
                                clickable: false,
                            });
                        } else {
                            if (
                                salePublic.totalPurchasedTokens === salePublic.claimedTokens ||
                                +utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals) === 0
                            ) {
                                setBtnInfo({
                                    firstText: `Claimed: ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimedTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    secondText: 'You have successfully participated!',
                                    clickable: false,
                                });
                            } else {
                                setBtnInfo({
                                    firstText: 'Claim',
                                    secondText: `Claim your unlocked ${formatterFloat.format(
                                        utils.formatUnits(salePublic.claimableTokens, salePublic.project.tokenDecimals)
                                    )} ${salePublic.project?.tickerSymbol ?? 'TBA'}`,
                                    clickable: true,
                                });
                            }
                        }
                        break;
                    case SALE_STATUS.ClaimingRefund:
                        setBtnInfo({
                            firstText: 'Claim refund',
                            secondText: `Claim your refund: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            clickable: true,
                        });
                        break;
                    case SALE_STATUS.ClaimingRefundEnd:
                        setBtnInfo({
                            firstText: `You've claimed: ${formatterFloat.format(
                                utils.formatUnits(salePublic.contribution, salePublic.paymentTokenDecimals)
                            )} ${salePublic.paymentTokenSymbol}`,
                            secondText: 'Refund claimed',
                            clickable: false,
                        });
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [account, salePublic, currentTime, kycStatus, personalInfo.membershipStatus]);

    const onBtnClicked = async () => {
        if (!account || !isValidWallet) {
            openModal('connectWallet');
            return;
        }

        if (isPreview) {
            window.scroll(0, 0);
            navigate(`/sales/${data.id}`);
            return;
        }
        const currentSaleStatus = getCurrentSaleStatus();

        if (
            (currentSaleStatus === SALE_STATUS.Open || currentSaleStatus === SALE_STATUS.New) &&
            data.type !== SALE_TYPE.Private
        ) {
            if (kycStatus !== KYC_STATUS.VERIFIED || kycStatus !== KYC_STATUS.VERIFYING) {
                navigate('/member/kyc');
                return;
            }

            if (
                salePublic.type === SALE_TYPE.TierBased &&
                personalInfo.membershipStatus === MEMBERSHIP_STATUS.Visitor
            ) {
                navigate('/member');
                return;
            }

            await fetchWrapper
                .get(`/api/Sale/CanApply/${salePublic.id}`)
                .then((res) => {
                    if (res.result === true) navigate('/whitelist');
                    else toast.error(res.reason);
                })
                .catch((err) => {
                    console.log('/api/Sale/CanApply', err);
                });
            return;
        }

        if (currentSaleStatus >= SALE_STATUS.Round1 && currentSaleStatus <= SALE_STATUS.Round3) {
            openModal('contribute');
            return;
        }

        if (currentSaleStatus === SALE_STATUS.ClaimingRefund) {
            setControlButtonStatus(CONTROL_BUTTON_STATUS.Claiming);

            try {
                setModalData({ status: 'pending', title: 'Refund claiming...' });
                openModal('transactionStatus');

                const saleABI = getProperABI(salePublic.type);
                const saleContract = new Contract(salePublic.saleAddress, saleABI, activeProvider.getSigner());
                const take = await saleContract.refund();
                const receipt = await take.wait();
                const events = abiDecoder.decodeLogs(receipt.logs);
                console.log(events);
                setModalData({
                    status: 'success',
                    tx: receipt.transactionHash,
                    title: `Refund claimed succesfully`,
                    claiming: true,
                });
            } catch (error) {
                console.log(error);
                toast.error(parseMetamaskError(error.message));
                setModalData({ status: 'fail', title: 'Refund claiming failed' });
            } finally {
                setControlButtonStatus(CONTROL_BUTTON_STATUS.None);
            }

            return;
        }

        if (currentSaleStatus >= SALE_STATUS.Claiming) {
            setControlButtonStatus(CONTROL_BUTTON_STATUS.Claiming);

            try {
                setModalData({ status: 'pending', title: 'Claiming...' });
                openModal('transactionStatus');
                const vestingContract = new Contract(
                    salePublic.vestingAddress,
                    IntervalVestingABI,
                    activeProvider.getSigner()
                );
                const take = await vestingContract.claimTokens();
                const receipt = await take.wait();
                const events = abiDecoder.decodeLogs(receipt.logs);
                console.log(events);
                setModalData({
                    status: 'success',
                    tx: receipt.transactionHash,
                    title: `Claimed succesfully`,
                    claiming: true,
                });
                openModal('transactionStatus');
            } catch (error) {
                console.log(error);
                toast.error(parseMetamaskError(error.message));
                setModalData({ status: 'fail', title: 'Claiming failed' });
                openModal('transactionStatus');
            } finally {
                setControlButtonStatus(CONTROL_BUTTON_STATUS.None);
            }
        }
    };

    return (
        <div className={clsx('control-button')} {...rest}>
            {btnInfo.clickable ? (
                <SimpleButton clickHandler={onBtnClicked} isDisabled={btnInfo.disabled || controlButtonStatus > 0}>
                    {btnInfo.firstText}
                    {controlButtonStatus > 0 && (
                        <div className="spinner-wrapper">
                            <Loading3Dot width={32} height={32} />
                        </div>
                    )}
                </SimpleButton>
            ) : (
                <div className="button-status">
                    <span className="">{btnInfo.firstText}</span>
                </div>
            )}
            <span className="text-info">{btnInfo.secondText}</span>
        </div>
    );
};

export default ControlButton;
