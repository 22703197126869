import {
    // WalletConnectIcon, TrezorIcon,LedgerIcon,
    MetamaskIcon,
    CoinbaseIcon,
} from '../assets/icons/connectors';

const config = {
    quizCount: 3,
    fileSizeLimit: 1024 * 1024 * 5,
    walletConnections: [
        {
            name: 'MetaMask',
            icon: MetamaskIcon,
            disabled: false,
        },
        // {
        //     name: 'Wallet Connect',
        //     icon: WalletConnectIcon,
        //     disabled: true,
        // },
        // {
        //     name: 'Trezor',
        //     icon: TrezorIcon,
        //     disabled: true,
        // },
        {
            name: 'Coinbase Wallet',
            icon: CoinbaseIcon,
            disabled: false,
        },
        // {
        //     name: 'Ledger',
        //     icon: LedgerIcon,
        //     disabled: true,
        // },
    ],
    abis: {
        uniswap: {
            factory: [
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeToSetter',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'constructor',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'token0',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'token1',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'address',
                            name: 'pair',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    name: 'PairCreated',
                    type: 'event',
                },
                {
                    constant: true,
                    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                    name: 'allPairs',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'allPairsLength',
                    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                    ],
                    name: 'createPair',
                    outputs: [
                        {
                            internalType: 'address',
                            name: 'pair',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'feeTo',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'feeToSetter',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        { internalType: 'address', name: '', type: 'address' },
                        { internalType: 'address', name: '', type: 'address' },
                    ],
                    name: 'getPair',
                    outputs: [{ internalType: 'address', name: '', type: 'address' }],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeTo',
                            type: 'address',
                        },
                    ],
                    name: 'setFeeTo',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '_feeToSetter',
                            type: 'address',
                        },
                    ],
                    name: 'setFeeToSetter',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
            pair: [
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'Approval',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'Burn',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                    ],
                    name: 'Mint',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'sender',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0In',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1In',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount0Out',
                            type: 'uint256',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'amount1Out',
                            type: 'uint256',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'Swap',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: false,
                            internalType: 'uint112',
                            name: 'reserve0',
                            type: 'uint112',
                        },
                        {
                            indexed: false,
                            internalType: 'uint112',
                            name: 'reserve1',
                            type: 'uint112',
                        },
                    ],
                    name: 'Sync',
                    type: 'event',
                },
                {
                    anonymous: false,
                    inputs: [
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'from',
                            type: 'address',
                        },
                        {
                            indexed: true,
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            indexed: false,
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'Transfer',
                    type: 'event',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'DOMAIN_SEPARATOR',
                    outputs: [
                        {
                            internalType: 'bytes32',
                            name: '',
                            type: 'bytes32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'MINIMUM_LIQUIDITY',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'PERMIT_TYPEHASH',
                    outputs: [
                        {
                            internalType: 'bytes32',
                            name: '',
                            type: 'bytes32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                    ],
                    name: 'allowance',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'approve',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                    ],
                    name: 'balanceOf',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'burn',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amount0',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount1',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'decimals',
                    outputs: [
                        {
                            internalType: 'uint8',
                            name: '',
                            type: 'uint8',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'factory',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'getReserves',
                    outputs: [
                        {
                            internalType: 'uint112',
                            name: 'reserve0',
                            type: 'uint112',
                        },
                        {
                            internalType: 'uint112',
                            name: 'reserve1',
                            type: 'uint112',
                        },
                        {
                            internalType: 'uint32',
                            name: 'blockTimestampLast',
                            type: 'uint32',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    name: 'initialize',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'kLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'mint',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'name',
                    outputs: [
                        {
                            internalType: 'string',
                            name: '',
                            type: 'string',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                    ],
                    name: 'nonces',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'owner',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'spender',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'permit',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'price0CumulativeLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'price1CumulativeLast',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                    ],
                    name: 'skim',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amount0Out',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amount1Out',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'bytes',
                            name: 'data',
                            type: 'bytes',
                        },
                    ],
                    name: 'swap',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'symbol',
                    outputs: [
                        {
                            internalType: 'string',
                            name: '',
                            type: 'string',
                        },
                    ],
                    payable: false,
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [],
                    name: 'sync',
                    outputs: [],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'token0',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'token1',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: true,
                    inputs: [],
                    name: 'totalSupply',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: '',
                            type: 'uint256',
                        },
                    ],
                    payable: false,
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'transfer',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    constant: false,
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'from',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'value',
                            type: 'uint256',
                        },
                    ],
                    name: 'transferFrom',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    payable: false,
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
            router: [
                {
                    inputs: [],
                    name: 'WETH',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountADesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBDesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'addLiquidity',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenDesired',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'addLiquidityETH',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [],
                    name: 'factory',
                    outputs: [
                        {
                            internalType: 'address',
                            name: '',
                            type: 'address',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveOut',
                            type: 'uint256',
                        },
                    ],
                    name: 'getAmountIn',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveOut',
                            type: 'uint256',
                        },
                    ],
                    name: 'getAmountOut',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                    ],
                    name: 'getAmountsIn',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                    ],
                    name: 'getAmountsOut',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'view',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'reserveB',
                            type: 'uint256',
                        },
                    ],
                    name: 'quote',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'pure',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidity',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidityETH',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'removeLiquidityETHSupportingFeeOnTransferTokens',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityETHWithPermit',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountToken',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'token',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountTokenMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountETHMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountETH',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'address',
                            name: 'tokenA',
                            type: 'address',
                        },
                        {
                            internalType: 'address',
                            name: 'tokenB',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'liquidity',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountAMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountBMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                        {
                            internalType: 'bool',
                            name: 'approveMax',
                            type: 'bool',
                        },
                        {
                            internalType: 'uint8',
                            name: 'v',
                            type: 'uint8',
                        },
                        {
                            internalType: 'bytes32',
                            name: 'r',
                            type: 'bytes32',
                        },
                        {
                            internalType: 'bytes32',
                            name: 's',
                            type: 'bytes32',
                        },
                    ],
                    name: 'removeLiquidityWithPermit',
                    outputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountA',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountB',
                            type: 'uint256',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapETHForExactTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactETHForTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'payable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForETH',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountIn',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountOutMin',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapExactTokensForTokensSupportingFeeOnTransferTokens',
                    outputs: [],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMax',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapTokensForExactETH',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
                {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'amountOut',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'amountInMax',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address[]',
                            name: 'path',
                            type: 'address[]',
                        },
                        {
                            internalType: 'address',
                            name: 'to',
                            type: 'address',
                        },
                        {
                            internalType: 'uint256',
                            name: 'deadline',
                            type: 'uint256',
                        },
                    ],
                    name: 'swapTokensForExactTokens',
                    outputs: [
                        {
                            internalType: 'uint256[]',
                            name: 'amounts',
                            type: 'uint256[]',
                        },
                    ],
                    stateMutability: 'nonpayable',
                    type: 'function',
                },
            ],
        },
        erc20: [
            {
                constant: false,
                inputs: [
                    {
                        name: '_spender',
                        type: 'address',
                    },
                    {
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'approve',
                outputs: [
                    {
                        name: 'success',
                        type: 'bool',
                    },
                ],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    {
                        name: '_to',
                        type: 'address',
                    },
                    {
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'showMeTheMoney',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    {
                        name: '_to',
                        type: 'address',
                    },
                    {
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'transfer',
                outputs: [
                    {
                        name: 'success',
                        type: 'bool',
                    },
                ],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    {
                        name: '_from',
                        type: 'address',
                    },
                    {
                        name: '_to',
                        type: 'address',
                    },
                    {
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'transferFrom',
                outputs: [
                    {
                        name: 'success',
                        type: 'bool',
                    },
                ],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        name: '_from',
                        type: 'address',
                    },
                    {
                        indexed: true,
                        name: '_to',
                        type: 'address',
                    },
                    {
                        indexed: false,
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'Transfer',
                type: 'event',
            },
            {
                anonymous: false,
                inputs: [
                    {
                        indexed: true,
                        name: '_owner',
                        type: 'address',
                    },
                    {
                        indexed: true,
                        name: '_spender',
                        type: 'address',
                    },
                    {
                        indexed: false,
                        name: '_value',
                        type: 'uint256',
                    },
                ],
                name: 'Approval',
                type: 'event',
            },
            {
                constant: true,
                inputs: [
                    {
                        name: '_owner',
                        type: 'address',
                    },
                    {
                        name: '_spender',
                        type: 'address',
                    },
                ],
                name: 'allowance',
                outputs: [
                    {
                        name: 'remaining',
                        type: 'uint256',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [
                    {
                        name: '_owner',
                        type: 'address',
                    },
                ],
                name: 'balanceOf',
                outputs: [
                    {
                        name: 'balance',
                        type: 'uint256',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [],
                name: 'decimals',
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [],
                name: 'name',
                outputs: [
                    {
                        name: '',
                        type: 'string',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [],
                name: 'symbol',
                outputs: [
                    {
                        name: '',
                        type: 'string',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [],
                name: 'totalSupply',
                outputs: [
                    {
                        name: '',
                        type: 'uint256',
                    },
                ],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
        ],
        erc721: [
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'approve',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'mint',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'safeTransferFrom',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                    { internalType: 'bytes', name: '_data', type: 'bytes' },
                ],
                name: 'safeTransferFrom',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'bool', name: 'approved', type: 'bool' },
                ],
                name: 'setApprovalForAll',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            {
                constant: false,
                inputs: [
                    { internalType: 'address', name: 'from', type: 'address' },
                    { internalType: 'address', name: 'to', type: 'address' },
                    { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'transferFrom',
                outputs: [],
                payable: false,
                stateMutability: 'nonpayable',
                type: 'function',
            },
            { inputs: [], payable: false, stateMutability: 'nonpayable', type: 'constructor' },
            {
                anonymous: false,
                inputs: [
                    { indexed: true, internalType: 'address', name: 'from', type: 'address' },
                    { indexed: true, internalType: 'address', name: 'to', type: 'address' },
                    { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'Transfer',
                type: 'event',
            },
            {
                anonymous: false,
                inputs: [
                    { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                    { indexed: true, internalType: 'address', name: 'approved', type: 'address' },
                    { indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256' },
                ],
                name: 'Approval',
                type: 'event',
            },
            {
                anonymous: false,
                inputs: [
                    { indexed: true, internalType: 'address', name: 'owner', type: 'address' },
                    { indexed: true, internalType: 'address', name: 'operator', type: 'address' },
                    { indexed: false, internalType: 'bool', name: 'approved', type: 'bool' },
                ],
                name: 'ApprovalForAll',
                type: 'event',
            },
            {
                constant: true,
                inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
                name: 'balanceOf',
                outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
                name: 'getApproved',
                outputs: [{ internalType: 'address', name: '', type: 'address' }],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [
                    { internalType: 'address', name: 'owner', type: 'address' },
                    { internalType: 'address', name: 'operator', type: 'address' },
                ],
                name: 'isApprovedForAll',
                outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
                name: 'ownerOf',
                outputs: [{ internalType: 'address', name: '', type: 'address' }],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
            {
                constant: true,
                inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
                name: 'supportsInterface',
                outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
                payable: false,
                stateMutability: 'view',
                type: 'function',
            },
        ],
    },
};

export default config;
