export const SET_GLOBAL_TIME = 'SET_GLOBAL_TIME';
export const SET_TIME_OFFSET = 'SET_TIME_OFFSET';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';

export const SET_ROUND1_STATUS = 'SET_ROUND1_STATUS';
export const SET_ROUND2_STATUS = 'SET_ROUND2_STATUS';
export const SET_CLAIM_STATUS = 'SET_CLAIM_STATUS';
export const SET_CLAIM_INFO = 'SET_CLAIM_INFO';
export const SET_WALLET_CONNECTION = 'SET_WALLET_CONNECTION';
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET';
export const SET_WALLET_STATUS = 'SET_WALLET_STATUS';
export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';
export const SET_SALE_PUBLIC = 'SET_SALE_PUBLIC';
export const SET_USER_BALANCE = 'SET_USER_BALANCE';
export const SET_OTA_BALANCE = 'SET_OTA_BALANCE';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_FARMING_POOLS = 'SET_FARMING_POOLS';
export const SET_FARMING_PARTICIPANTS = 'SET_FARMING_PARTICIPANTS';
export const SET_STAKING_POOLS = 'SET_STAKING_POOLS';
export const SET_STAKING_PARTICIPANTS = 'SET_STAKING_PARTICIPANTS';
export const SET_PENDING_EMAIL = 'SET_PENDING_EMAIL';
export const SET_KYC_EMAIL = 'SET_KYC_EMAIL';
export const SET_PUBLIC_INFO = 'SET_PUBLIC_INFO';
export const SET_TIERS_INFO = 'SET_TIERS_INFO';
export const SET_TIERS_UPDATE = 'SET_TIERS_UPDATE';
export const SET_CURRENT_TIER = 'SET_CURRENT_TIER';
export const SET_GLOBAL_TIME_OFFSET = 'SET_GLOBAL_TIME_OFFSET';
export const SET_ACCOUNT = 'SET_ACCOUNT';

export const SET_CONTROL_BUTTON_STATUS = 'SET_CONTROL_BUTTON_STATUS';
export const SET_WRONG_NETWORK = 'SET_WRONG_NETWORK';
export const SET_SALES_LIST = 'SET_SALES_LIST';

export const SET_KYC_SUBMITTING = 'SET_KYC_SUBMITTING';
export const SET_KYC_STATUS = 'SET_KYC_STATUS';
export const SET_KYC_INFO = 'SET_KYC_INFO';
