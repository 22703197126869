import React from 'react';

const Arrow = () => {
    return (
        <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="12.252" y1="25.0005" x2="12.252" y2="1.00049" stroke="#3BDCB1" strokeWidth="2" strokeLinecap="round"/>
            <line x1="11.7928" y1="26.6484" x2="1.2518" y2="16.1074" stroke="#3BDCB1" strokeWidth="2" strokeLinecap="round"/>
            <line x1="1" y1="-1" x2="15.9073" y2="-1" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 11.4414 26.6484)" stroke="#3BDCB1" strokeWidth="2" strokeLinecap="round"/>
        </svg>

    );
};

export default Arrow;
