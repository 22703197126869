import React from 'react';

const StopWatch = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M13.9998 24.5C12.1539 24.5 10.3494 23.9527 8.81452 22.9271C7.27966 21.9015 6.08338 20.4439 5.37697 18.7384C4.67055 17.033 4.48572 15.1564 4.84585 13.3459C5.20597 11.5354 6.09489 9.87233 7.40018 8.56704C8.70547 7.26175 10.3685 6.37284 12.179 6.01271C13.9895 5.65258 15.8661 5.83741 17.5716 6.54383C19.277 7.25025 20.7347 8.44653 21.7602 9.98139C22.7858 11.5162 23.3332 13.3207 23.3332 15.1667C23.3301 17.6411 22.3458 20.0133 20.5961 21.763C18.8464 23.5126 16.4742 24.497 13.9998 24.5V24.5ZM13.9998 8.16671C12.6154 8.16671 11.262 8.57725 10.1108 9.34642C8.9597 10.1156 8.0625 11.2088 7.53268 12.4879C7.00287 13.767 6.86425 15.1745 7.13434 16.5323C7.40444 17.8902 8.07113 19.1375 9.05009 20.1165C10.0291 21.0954 11.2763 21.7621 12.6342 22.0322C13.9921 22.3023 15.3995 22.1637 16.6786 21.6339C17.9577 21.1041 19.051 20.2068 19.8201 19.0557C20.5893 17.9046 20.9998 16.5512 20.9998 15.1667C20.9977 13.3109 20.2595 11.5316 18.9472 10.2193C17.6349 8.90706 15.8557 8.16887 13.9998 8.16671V8.16671ZM15.1665 16.3334H12.8332V10.5H15.1665V16.3334ZM22.5083 8.99154L20.175 6.65821L21.8247 5.00854L24.158 7.34187L22.5095 8.99037L22.5083 8.99154V8.99154ZM17.4998 4.66671H10.4998V2.33337H17.4998V4.66671Z" 
                fill="#3BDCB1"
            />
        </svg>
    );
};

export default StopWatch;
