import React from 'react';

const KYCStatus = () => (
    <svg 
        width="28" 
        height="28" 
        viewBox="0 0 28 28" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M14.0082 27.5999C21.5193 27.5999 27.6082 21.511 27.6082 13.9999C27.6082 6.48883 21.5193 0.399902 14.0082 0.399902C6.49713 0.399902 0.408203 6.48883 0.408203 13.9999C0.408203 21.511 6.49713 27.5999 14.0082 27.5999ZM12.3093 25.2065C8.84665 24.6858 5.897 22.5986 4.20503 19.6896C4.44494 16.6947 6.99202 14.3379 10.0474 14.3379C12.7147 14.3379 15.0007 16.1054 15.7465 18.5331C14.0272 19.6543 12.3451 21.3301 12.3093 25.2065ZM13.6397 9.61134C13.6397 11.5691 12.0526 13.1563 10.0948 13.1563C8.13701 13.1563 6.54991 11.5691 6.54991 9.61134C6.54991 7.65353 8.13701 6.06642 10.0948 6.06642C12.0526 6.06642 13.6397 7.65353 13.6397 9.61134ZM23.2107 20.6168C21.1575 23.467 17.8126 25.3248 14.0335 25.3329V24.0584C14.0335 21.2486 16.3113 18.9708 19.1211 18.9708H19.7516C21.1474 18.9708 22.3935 19.6124 23.2107 20.6168ZM22.1737 14.9006C22.1737 16.5865 20.8071 17.9532 19.1212 17.9532C17.4353 17.9532 16.0686 16.5865 16.0686 14.9006C16.0686 13.2147 17.4353 11.8481 19.1212 11.8481C20.8071 11.8481 22.1737 13.2147 22.1737 14.9006Z" fill="white"/>
    </svg>

);

export default KYCStatus;
