import React from 'react';

const Farming = () => {
    return (
        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.50804 1C3.50804 0.447715 3.95576 0 4.50804 0H11.0384C11.4205 0 11.7692 0.21777 11.9369 0.561132L15.1167 7.07143H21.2567C22.408 7.07143 22.408 7.85714 22.408 8.55643L21.6405 14.0714L21.0495 14.1429C21.1877 14.52 21.2567 14.9207 21.2567 15.3214C21.2567 16.2592 20.8929 17.1585 20.2451 17.8216C19.5974 18.4846 18.7189 18.8571 17.8029 18.8571C16.8869 18.8571 16.0084 18.4846 15.3607 17.8216C14.713 17.1585 14.3491 16.2592 14.3491 15.3214C14.3491 14.9207 14.4182 14.52 14.5449 14.1429H11.2446C10.9452 16.83 8.73479 18.8571 6.09839 18.8571C3.22022 18.8571 0.91769 16.5 0.91769 13.5536C0.91769 12.6154 1.16016 11.7187 1.59432 10.9378L0.408203 10.1283C1.21685 8.88659 2.34181 8.0043 3.50804 7.45934V1ZM7.93005 11.6785C7.44426 11.1812 6.7854 10.9018 6.09839 10.9018C5.41139 10.9018 4.75252 11.1812 4.26674 11.6785C3.78095 12.1758 3.50804 12.8503 3.50804 13.5536C3.50804 14.2569 3.78095 14.9314 4.26674 15.4287C4.75252 15.926 5.41139 16.2054 6.09839 16.2054C6.7854 16.2054 7.44426 15.926 7.93005 15.4287C8.41583 14.9314 8.68874 14.2569 8.68874 13.5536C8.68874 12.8503 8.41583 12.1758 7.93005 11.6785ZM19.024 14.0714C18.7002 13.7398 18.2609 13.5536 17.8029 13.5536C17.3449 13.5536 16.9057 13.7398 16.5818 14.0714C16.258 14.4029 16.076 14.8526 16.076 15.3214C16.076 15.7903 16.258 16.24 16.5818 16.5715C16.9057 16.903 17.3449 17.0893 17.8029 17.0893C18.2609 17.0893 18.7002 16.903 19.024 16.5715C19.3479 16.24 19.5298 15.7903 19.5298 15.3214C19.5298 14.8526 19.3479 14.4029 19.024 14.0714Z" fill="white"/>
        </svg>
    );
};

export default Farming;
