import Visitor1 from '../assets/png/Visitor.webp';
import Citizen1 from '../assets/png/Citizen.webp';
import Officer1 from '../assets/png/Officer.webp';
import Ambassador1 from '../assets/png/Ambassador.webp';
import Senator1 from '../assets/png/Senator.webp';

export const MemberShipTiers = [
    {
        id: null,
        name: 'Visitor',
        logo: Visitor1,
        blockchainTierId: -1,
        basePrice: null,
        priceMultiplier: null,
        totalAmount: null,
        mintedAmount: null,
        idoAllocation: null,
        tierAddress: null,
        hasExclusiveSaleAllocation: false,
        isExists: true,
    },
    {
        id: null,
        name: 'Citizen',
        logo: Citizen1,
        blockchainTierId: 0,
        basePrice: null,
        priceMultiplier: null,
        totalAmount: null,
        mintedAmount: null,
        idoAllocation: null,
        tierAddress: null,
        hasExclusiveSaleAllocation: false,
        isExists: false,
    },
    {
        id: null,
        name: 'Officer',
        logo: Officer1,
        blockchainTierId: 1,
        basePrice: null,
        priceMultiplier: null,
        totalAmount: null,
        mintedAmount: null,
        idoAllocation: null,
        tierAddress: null,
        hasExclusiveSaleAllocation: false,
        isExists: false,
    },
    {
        id: null,
        name: 'Ambassador',
        logo: Ambassador1,
        blockchainTierId: 2,
        basePrice: null,
        priceMultiplier: null,
        totalAmount: null,
        mintedAmount: null,
        idoAllocation: null,
        tierAddress: null,
        hasExclusiveSaleAllocation: false,
        isExists: false,
    },
    {
        id: null,
        name: 'Senator',
        logo: Senator1,
        blockchainTierId: 3,
        basePrice: null,
        priceMultiplier: null,
        totalAmount: null,
        mintedAmount: null,
        idoAllocation: null,
        tierAddress: null,
        hasExclusiveSaleAllocation: false,
        isExists: false,
    },
];
