import React from 'react';

const InfoMark = () => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.4082 21.8799C22.4082 22.4322 21.9551 22.896 21.4148 22.7815C20.7811 22.6471 20.3513 22.4005 20.0102 22.2041C19.6032 21.9718 19.3502 21.8239 18.7452 21.8239C18.1292 21.8239 17.8872 21.9612 17.4802 22.2041C16.9742 22.4892 16.3032 22.8799 15.0822 22.8799C13.8612 22.8799 13.1792 22.4892 12.6842 22.2041C12.2772 21.9718 12.0242 21.8239 11.4192 21.8239C10.8032 21.8239 10.5612 21.9612 10.1542 22.2041C9.6482 22.4892 8.9662 22.8799 7.7452 22.8799C6.5242 22.8799 5.8422 22.4892 5.3472 22.2041C4.9402 21.9612 4.6872 21.8239 4.0822 21.8239C3.4772 21.8239 3.2242 21.9612 2.8172 22.2041C2.46851 22.4006 2.03624 22.6472 1.4016 22.7815C0.861287 22.8959 0.408203 22.4322 0.408203 21.8799V21.7679C0.408203 21.2156 0.883961 20.8115 1.37458 20.5579C1.46923 20.509 1.56543 20.4521 1.6732 20.3878C2.1792 20.1026 2.8612 19.7119 4.0822 19.7119C5.3032 19.7119 5.9852 20.1026 6.4802 20.3878C6.8872 20.6306 7.1292 20.7679 7.7452 20.7679C8.3612 20.7679 8.6032 20.6306 9.0102 20.3878C9.5162 20.1026 10.1982 19.7119 11.4192 19.7119C12.6402 19.7119 13.3222 20.1026 13.8172 20.3878C14.2242 20.6201 14.4772 20.7679 15.0822 20.7679C15.6872 20.7679 15.9402 20.6306 16.3472 20.3878C16.8422 20.1026 17.5242 19.7119 18.7452 19.7119C19.9662 19.7119 20.6482 20.1026 21.1432 20.3878C21.251 20.4521 21.3472 20.509 21.4418 20.5579C21.9324 20.8115 22.4082 21.2156 22.4082 21.7679V21.8799Z" fill="#FFF"/>
            <path d="M22.4082 17.4801C22.4082 18.0324 21.9551 18.4962 21.4148 18.3816C20.7811 18.2473 20.3513 18.0007 20.0102 17.8042C19.6032 17.5719 19.3502 17.4241 18.7452 17.4241C18.1292 17.4241 17.8872 17.5614 17.4802 17.8042C16.9742 18.0894 16.3032 18.4801 15.0822 18.4801C13.8612 18.4801 13.1792 18.0894 12.6842 17.8042C12.2772 17.5719 12.0242 17.4241 11.4192 17.4241C10.8032 17.4241 10.5612 17.5614 10.1542 17.8042C9.6482 18.0894 8.9662 18.4801 7.7452 18.4801C6.5242 18.4801 5.8422 18.0894 5.3472 17.8042C4.9402 17.5614 4.6872 17.4241 4.0822 17.4241C3.4772 17.4241 3.2242 17.5614 2.8172 17.8042C2.46851 18.0007 2.03624 18.2473 1.4016 18.3817C0.861287 18.4961 0.408203 18.0324 0.408203 17.4801V17.3681C0.408203 16.8158 0.883961 16.4116 1.37458 16.1581C1.46923 16.1091 1.56543 16.0522 1.6732 15.9879C2.1792 15.7028 2.8612 15.3121 4.0822 15.3121C5.3032 15.3121 5.9852 15.7028 6.4802 15.9879C6.8872 16.2308 7.1292 16.3681 7.7452 16.3681C8.3612 16.3681 8.6032 16.2308 9.0102 15.9879C9.5162 15.7028 10.1982 15.3121 11.4192 15.3121C12.6402 15.3121 13.3222 15.7028 13.8172 15.9879C14.2242 16.2202 14.4772 16.3681 15.0822 16.3681C15.6872 16.3681 15.9402 16.2308 16.3472 15.9879C16.8422 15.7028 17.5242 15.3121 18.7452 15.3121C19.9662 15.3121 20.6482 15.7028 21.1432 15.9879C21.251 16.0522 21.3472 16.1091 21.4418 16.1581C21.9324 16.4116 22.4082 16.8158 22.4082 17.3681V17.4801Z" fill="#FFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7139 13.2932C20.3122 12.0694 20.648 10.6939 20.648 9.24C20.648 4.13689 16.5111 0 11.408 0C6.30486 0 2.16797 4.13689 2.16797 9.24C2.16797 10.6947 2.50415 12.071 3.10307 13.2952C3.38594 13.2364 3.70881 13.1999 4.08204 13.1999C5.30304 13.1999 5.98504 13.5906 6.48004 13.8757C6.88704 14.1186 7.12904 14.2559 7.74504 14.2559C8.36104 14.2559 8.60304 14.1186 9.01004 13.8757L9.02396 13.8679C9.52855 13.5835 10.2092 13.1999 11.419 13.1999C12.64 13.1999 13.322 13.5906 13.817 13.8757L13.8289 13.8825C14.229 14.1109 14.4829 14.2559 15.082 14.2559C15.687 14.2559 15.94 14.1186 16.347 13.8757C16.842 13.5906 17.524 13.1999 18.745 13.1999C19.1139 13.1999 19.4337 13.2355 19.7139 13.2932Z" fill="#FFF"/>
        </svg>
    );
};

export default InfoMark;
