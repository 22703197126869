import React from 'react';

const Staking = () => {
    return (
        <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <rect x="0.408203" y="12.1439" width="18.2284" height="4.72263" rx="2" fill="white"/>
                <rect x="4.18018" y="6.0719" width="18.2284" height="4.72263" rx="2" fill="white"/>
                <rect x="0.408203" width="18.2284" height="4.72263" rx="2" fill="white"/>
            </g>
        </svg>
    );
};

export default Staking;
