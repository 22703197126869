import React from 'react';

const InfoMark = () => {
    return (
        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4082 0C5.3362 0 0.408203 4.928 0.408203 11C0.408203 17.072 5.3362 22 11.4082 22C17.4802 22 22.4082 17.072 22.4082 11C22.4082 4.928 17.4802 0 11.4082 0ZM12.5082 15.4C12.5082 16.0075 12.0157 16.5 11.4082 16.5C10.8007 16.5 10.3082 16.0075 10.3082 15.4V11C10.3082 10.3925 10.8007 9.9 11.4082 9.9C12.0157 9.9 12.5082 10.3925 12.5082 11V15.4ZM12.5082 6.6C12.5082 7.20751 12.0157 7.7 11.4082 7.7C10.8007 7.7 10.3082 7.20751 10.3082 6.6C10.3082 5.99249 10.8007 5.5 11.4082 5.5C12.0157 5.5 12.5082 5.99249 12.5082 6.6Z" fill="white"/>
        </svg>

    );
};

export default InfoMark;
