import { KYC_STATUS, MEMBERSHIP_STATUS, SALE_TYPE } from '../config/constants';
import PrivateSaleABI from '../config/abi/PrivateSale.json';
import UnlimitedSaleABI from '../config/abi/UnlimitedSale.json';
import TierBasedSaleABI from '../config/abi/TierBasedSale.json';
import { utils } from 'ethers';

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return '';
}

export function getRandom(arr, n) {
    let result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len) throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export const getNumberSuffix = (value, decimals = 2) => {
    if (typeof value !== 'number') throw new Error('Value must be a number');

    if (isNaN(value)) return '0';

    if (value === Infinity) return value;

    if (value >= 1000000000000) return `${+(value / 1000000000000).toFixed(decimals).toLocaleString()} T `;

    if (value >= 1000000000) return `${+(value / 1000000000).toFixed(decimals).toLocaleString()} B `;

    if (value >= 1000000) return `${+(value / 1000000).toFixed(decimals).toLocaleString()} M `;

    // if (value >= 1000) return `${+(value / 1000).toFixed(decimals).toLocaleString()} K `;

    return +value.toFixed(decimals).toLocaleString();
};

export const hasError = (errors, field) => {
    if (!errors || errors.length < 1) return false;

    let err = null;
    for (const key in errors) {
        if (errors[key].errorType !== field) continue;

        err = errors[key];
        break;
    }

    if (!err) return false;

    return err.errorMessage;
};

export const parseMarkdown = (value) => {
    const result = [];
    const contents = value.split('\n');
    for (const sentence of contents) {
        if (!sentence.trim()) continue;

        if (sentence.startsWith('## ')) {
            result.push({
                type: 'subtitle',
                value: sentence.substring(2).trim().toLowerCase().replaceAll(' ', '-'),
                label: sentence.substring(2).trim(),
            });
        } else if (sentence.startsWith('# ')) {
            result.push({
                type: 'title',
                value: sentence.substring(1).trim().toLowerCase().replaceAll(' ', '-'),
                label: sentence.substring(1).trim(),
            });
        }
    }

    return result;
};

export const convertRes2KYCStatus = (res) => {
    if (res === 'NotStarted') return KYC_STATUS.UNVERIFIED;
    if (res === 'Processing') return KYC_STATUS.VERIFYING;
    if (res === 'Error' || res === 'Failed') return KYC_STATUS.FAILED;
    if (res === 'Completed') return KYC_STATUS.VERIFIED;
    return KYC_STATUS.NONE;
};

export const getStatusWithTierId = (tierId, tiers) => {
    const matchedTier = tiers.find((tier) => tier.id === tierId);
    if (!matchedTier) return MEMBERSHIP_STATUS.Visitor;

    return matchedTier.name;
};

export const currentAvailableInfo = (id, data) => {
    const result = data.filter((tier) => tier.membershipTierId === id);
    if (result.length === 0)
        return { blockchainTierId: 0, spots: '0', applied: '0', contribution: null, allocation: null };
    else return result[0];
};

export const parseMetamaskError = (msg) => {
    let startPos = msg.indexOf(`"originalError":`);
    if (startPos === -1) return msg;
    startPos += `"originalError":`.length;

    let endPos = msg.indexOf(`"}`, startPos);
    if (endPos === -1) return msg;
    endPos += `"}`.length;

    let originError = msg.substring(startPos, endPos);
    originError = JSON.parse(originError);

    return originError.message ?? msg;
};

export const getProperABI = (type) => {
    if (type === SALE_TYPE.Private) return PrivateSaleABI;
    if (type === SALE_TYPE.Unlimited) return UnlimitedSaleABI;
    return TierBasedSaleABI;
};

export const convertGuidToBytes16 = (guidStr) => {
    if (guidStr === '0') return '0x00000000000000000000000000000000';
    // convert guid string to 16 byte array
    var bytes = [];
    guidStr.split('-').map((number, index) => {
        var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g);
        bytesInChar.map((byte) => {
            bytes.push(parseInt(byte, 16));
            return '';
        });

        return '';
    });

    const hexString = Array.from(bytes, function (byte) {
        return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');

    return `0x${hexString}`;
};

export const formatterInt = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
});

export const formatterSmallFloat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
});

export const formatterFloat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 3,
});

export const checkIsSaleCompleted = (data) => {
    if (
        !data.maximumRaiseAmountInPaymentToken ||
        !data?.minimumContributionLeft ||
        !data?.totalApplied ||
        !data?.paymentTokenDecimals
    )
        return false;
    return (
        parseFloat(utils.formatUnits(data.maximumRaiseAmountInPaymentToken, data.paymentTokenDecimals)) -
            parseFloat(utils.formatUnits(data.minimumContributionLeft, data.paymentTokenDecimals)) -
            parseFloat(utils.formatUnits(data.totalApplied.toString(), data.paymentTokenDecimals)) <=
            0 ||
        new Date().getDate() - new Date(data.rounds[data.rounds.length - 1].endsAt).getDate() > 0 ||
        data.isCancelled
    );
};
// Data required:
// Total Liquidity
// Reward/second
// Base token exchange rate to USD value
// Reward token exchange rate to USD value

// APR = (USD value of reward tokens throughout a year) / (USD value of Total Liquidity)

// Number of periods = 52 (weeks)

// APY = [1 + (APR / Number of Periods)]^(Number of Periods) - 1

// This assumes a weekly restaking of rewards.
export const calculateAPY = ({
    baseTokenTotalStakedAmount,
    baseTokenUSDValue,
    startTime,
    endTime,
    rewardTotalUSDValue,
}) => {
    if (!baseTokenTotalStakedAmount || !baseTokenUSDValue || !startTime || !endTime || !rewardTotalUSDValue) {
        return;
    }
    const duration = new Date(endTime * 1000).getTime() - new Date(startTime * 1000).getTime();
    const rewardMSecondParity = rewardTotalUSDValue / duration;
    const rewardYearParity = rewardMSecondParity * 1000 * 60 * 60 * 24 * 365;
    const totalLiquidity = baseTokenUSDValue * baseTokenTotalStakedAmount;
    const APR = rewardYearParity / totalLiquidity;
    const numberOfWeeksInYear = 52;
    const APY = Math.pow(1 + APR / numberOfWeeksInYear, numberOfWeeksInYear) - 1;
    return APY * 100;
};

export const calculateTotalAPY = ({
    rewards,
    baseTokenTotalStakedAmount,
    baseTokenDecimals,
    baseTokenUSDValue,
    startTime,
    endTime,
}) => {
    let totalAPY = 0;
    console.log('');
    rewards.forEach((reward) => {
        totalAPY += calculateAPY({
            baseTokenTotalStakedAmount: Number(
                utils.formatUnits(utils.parseUnits(baseTokenTotalStakedAmount, 0), baseTokenDecimals)
            ),
            baseTokenUSDValue: Number(baseTokenUSDValue),
            startTime,
            endTime,
            rewardTotalUSDValue:
                Number(reward.usdValue) *
                Number(utils.formatUnits(utils.parseUnits(reward.totalAmount, 0), reward.decimals)),
        });
    });
    return totalAPY;
};
