import ethIcon from '../assets/icons/tokens/ether.svg';
import usdcIcon from '../assets/icons/tokens/usdc.svg';
import otarisIcon from '../assets/icons/tokens/otaris.svg';
import defaultIcon from '../assets/icons/tokens/otaris.svg';

const tokenInfo = {
    USDC: {
        icon: usdcIcon,
    },
    OTA: {
        icon: otarisIcon,
    },
    ETH: {
        icon: ethIcon,
    },
    default: {
        icon: defaultIcon,
    },
};

export default tokenInfo;
