import React from 'react';

const Globe = ({fill}) => {
    return (
        <svg 
            width="20" 
            height="19" 
            viewBox="0 0 20 19" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M9.85717 0.37854C4.63781 0.37854 0.393326 4.45419 0.393326 9.46288C0.393326 14.4716 4.63781 18.5472 9.85717 18.5472C15.0746 18.5472 19.32 14.4716 19.32 9.46288C19.32 4.45419 15.0746 0.37854 9.85717 0.37854ZM17.9282 9.46288C17.9282 11.2353 17.3013 12.8695 16.2564 14.1754C15.9607 13.954 15.6463 13.355 15.942 12.7342C16.2407 12.1096 16.3185 10.6637 16.2495 10.1007C16.1845 9.53858 15.8799 8.18445 15.0529 8.1712C14.2268 8.15985 13.6601 7.89773 13.1692 6.95712C12.1509 5.0002 15.0805 4.62452 14.0622 3.54197C13.7774 3.23727 12.3047 4.79296 12.0888 2.71965C12.075 2.57108 12.2219 2.34871 12.42 2.11781C15.6177 3.14926 17.9282 6.05152 17.9282 9.46288ZM8.74824 1.79134C8.55504 2.15283 8.04542 2.2995 7.73591 2.57108C7.06168 3.15683 6.77188 3.07545 6.40914 3.63755C6.04344 4.19964 4.86453 5.00872 4.86453 5.41562C4.86453 5.82252 5.4599 6.30134 5.75758 6.20861C6.05527 6.11398 6.84088 6.11965 7.3022 6.27485C7.7645 6.43193 11.1603 6.58901 10.078 9.3446C9.73494 10.2209 8.23173 10.0732 7.83153 11.5239C7.7714 11.7368 7.56341 12.6462 7.5506 12.9433C7.52595 13.4032 7.88968 15.1368 7.42738 15.1368C6.96311 15.1368 5.71421 13.5858 5.71421 13.3048C5.71421 13.0238 5.3909 12.0387 5.3909 11.1946C5.3909 10.3514 3.89458 10.3647 3.89458 9.24334C3.89458 8.23271 4.70583 7.72929 4.52347 7.24384C4.34505 6.76124 2.91971 6.74515 2.32533 6.68553C3.36328 4.10123 5.8049 2.18311 8.74824 1.79134ZM7.31797 16.8155C7.80294 16.5695 7.85222 16.2515 8.29185 16.2354C8.79457 16.2137 9.20364 16.0471 9.77042 15.927C10.2731 15.8219 11.1731 15.3317 11.9646 15.2693C12.6329 15.2172 13.9508 15.3024 14.3057 15.9213C13.0292 16.736 11.4984 17.2111 9.85618 17.2111C8.97002 17.2111 8.1164 17.0701 7.31797 16.8155Z" 
                fill={fill??''}
            />
        </svg>
    );
};

export default Globe;
