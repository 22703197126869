import React from 'react';
import { EllipseButton } from '../reusable';
const CustomInput = ({ value, setValue }) => {
    const onMaxBtnClick = () => {
        setValue(1000);
    };

    const getInputValue = (e) => {
        // if (e.target.value.length > 5) {
        //     setInputValue(0);
        // }
        // else if (e.target.value > 1000) {
        //     setInputValue(1000);
        // }
        // else {
        //     setInputValue(e.target.value);
        // }
        setValue(e.target.value.toString());
    };

    return (
        <div className="custom-input-container">
            <input type="number" value={value} onChange={getInputValue} placeholder="" />
            <EllipseButton clickHandler={onMaxBtnClick}>MAX</EllipseButton>
        </div>
    );
};

export default CustomInput;
