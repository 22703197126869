import React from 'react';

const Docs = () => {
    return (
        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.73564 0C1.44391 0 0.408203 1.02419 0.408203 2.30154V20.7139C0.408203 21.3243 0.653415 21.9097 1.08989 22.3413C1.52637 22.773 2.11837 23.0154 2.73564 23.0154H7.39052V20.8175L9.8227 18.4123H4.8147C4.30891 18.4123 3.89888 18.0023 3.89888 17.4965C3.89888 16.9907 4.30891 16.5807 4.8147 16.5807H11.7529L14.4776 13.8093H4.69483C4.25524 13.8093 3.89888 13.4529 3.89888 13.0133C3.89888 12.5737 4.25524 12.2174 4.69483 12.2174H16.1162L19.0277 9.30974V6.90463L12.0454 0H2.73564ZM10.8817 1.72616L17.2821 8.0554H11.8817C11.3294 8.0554 10.8817 7.60769 10.8817 7.0554V1.72616ZM20.2582 11.2776C20.0837 11.2776 19.9208 11.3351 19.7927 11.4617L18.6057 12.6355L21.0379 15.0291L22.2249 13.8668C22.4693 13.6136 22.4693 13.1994 22.2249 12.9577L20.7121 11.4617C20.5841 11.3351 20.4212 11.2776 20.2582 11.2776ZM17.9192 13.3144L9.13489 21.8167V24.2103H11.5554L20.3513 15.708L17.9192 13.3144Z" fill="white"/>
        </svg>

    );
};

export default Docs;
