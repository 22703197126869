import React from 'react';

const OtarisLogo = ({ isModal = false, isPhablet = false, width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 173 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M86.6528 136.008C112.499 136.008 132.986 133.146 148.881 122.654C166.667 110.645 172.983 91.1777 173 67.908C173 44.8306 166.684 25.1793 148.881 13.1621C132.969 2.85798 112.675 0 86.4469 0C60.6003 0 40.1134 2.67001 24.2187 13.1792C6.22291 25.0041 0.0957031 44.8562 0.0957031 67.9251C0.0957031 91.1777 6.22292 110.645 24.2358 122.466C40.1305 133.146 60.4245 136.008 86.6528 136.008ZM129.127 34.7486C120.019 28.8874 107.246 26.384 86.4635 26.384L86.4377 26.4011C75.847 26.4011 67.7945 26.9991 61.0928 28.2807C54.1337 29.6136 48.5168 31.7368 43.916 34.7742C39.2552 37.833 35.9193 41.9768 33.724 47.3852C31.5287 52.7936 30.4181 59.6288 30.4181 67.7969C30.4181 75.965 31.5244 82.8131 33.7111 88.1574C35.7203 93.2863 39.2883 97.6612 43.916 100.67L43.9718 100.709L44.0275 100.743C53.1347 106.865 65.4706 109.364 86.6178 109.364C107.332 109.364 120.041 106.8 129.088 100.867C134.941 96.9065 142.659 89.1527 142.659 67.7926C142.659 59.7399 141.535 52.9047 139.314 47.4749C137.29 42.3032 133.737 37.8655 129.127 34.7486ZM36.9012 24.2266C49.563 15.8833 65.8565 13.7473 86.4377 13.7473V13.7559C107.319 13.7559 123.475 16.0329 136.129 24.2351C150.304 33.7916 155.333 49.4401 155.333 67.8097C155.333 86.3289 150.304 101.819 136.129 111.384C123.467 119.74 107.173 122.017 86.5921 122.017C65.7107 122.017 49.5501 119.74 36.9012 111.235C22.5715 101.819 17.6964 86.3289 17.6964 67.8012C17.6964 49.4315 22.5715 33.625 36.9012 24.2266Z"
                fill={isModal ? 'white' : isPhablet ? '#26bfb4' : 'url(#paint0_linear_5311_16132)'}
            />
            <defs>
                <linearGradient
                    id="paint0_linear_5311_16132"
                    x1="30.9154"
                    y1="-2.07163e-07"
                    x2="86.0336"
                    y2="108.051"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3BDCB1" />
                    <stop offset="1" stopColor="#11A3B7" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default OtarisLogo;
