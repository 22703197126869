import React from 'react';

const QuestionMark = () => {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="18"
                cy="18"
                r="18"
                fill="#29C1B1"
            />
            <path
                d="M18 4C10.2687 4 3.99997 10.2687 3.99997 18C3.99997 25.7312 10.2687 32 18 32C25.7312 32 32 25.7312 32 18C32 10.2687 25.7312 4 18 4ZM18 29.625C11.5812 29.625 6.37497 24.4187 6.37497 18C6.37497 11.5812 11.5812 6.375 18 6.375C24.4187 6.375 29.625 11.5812 29.625 18C29.625 24.4187 24.4187 29.625 18 29.625Z"
                fill="white"
            />
            <path
                d="M21.4875 11.8969C20.55 11.075 19.3125 10.625 18 10.625C16.6875 10.625 15.45 11.0781 14.5125 11.8969C13.5375 12.75 13 13.8969 13 15.125V15.3625C13 15.5 13.1125 15.6125 13.25 15.6125H14.75C14.8875 15.6125 15 15.5 15 15.3625V15.125C15 13.7469 16.3468 12.625 18 12.625C19.6531 12.625 21 13.7469 21 15.125C21 16.0969 20.3125 16.9875 19.2468 17.3969C18.5843 17.65 18.0218 18.0938 17.6187 18.675C17.2093 19.2688 16.9968 19.9813 16.9968 20.7031V21.375C16.9968 21.5125 17.1093 21.625 17.2468 21.625H18.7468C18.8843 21.625 18.9968 21.5125 18.9968 21.375V20.6656C18.9985 20.3623 19.0915 20.0664 19.2637 19.8167C19.4359 19.567 19.6795 19.3749 19.9625 19.2656C21.8062 18.5563 22.9968 16.9313 22.9968 15.125C23 13.8969 22.4625 12.75 21.4875 11.8969ZM16.75 24.875C16.75 25.2065 16.8817 25.5245 17.1161 25.7589C17.3505 25.9933 17.6684 26.125 18 26.125C18.3315 26.125 18.6494 25.9933 18.8838 25.7589C19.1183 25.5245 19.25 25.2065 19.25 24.875C19.25 24.5435 19.1183 24.2255 18.8838 23.9911C18.6494 23.7567 18.3315 23.625 18 23.625C17.6684 23.625 17.3505 23.7567 17.1161 23.9911C16.8817 24.2255 16.75 24.5435 16.75 24.875Z"
                fill="white"
            />
        </svg>
    );
};

export default QuestionMark;
