import React from 'react';

const Info = () => {
    return (
        <svg
            width='41'
            height='41'
            viewBox='0 0 41 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M20.5 0.25C16.4949 0.25 12.5798 1.43764 9.24971 3.66274C5.91962 5.88784 3.32412 9.05046 1.79145 12.7507C0.258774 16.4509 -0.142244 20.5225 0.639107 24.4506C1.42046 28.3787 3.34908 31.9869 6.1811 34.8189C9.01311 37.6509 12.6213 39.5796 16.5494 40.3609C20.4775 41.1423 24.5491 40.7412 28.2494 39.2086C31.9496 37.6759 35.1122 35.0804 37.3373 31.7503C39.5624 28.4202 40.75 24.5051 40.75 20.5C40.744 15.1312 38.6085 9.98407 34.8122 6.18777C31.0159 2.39146 25.8688 0.256047 20.5 0.25ZM20.5 36.25C17.385 36.25 14.3399 35.3263 11.7498 33.5956C9.15971 31.865 7.14099 29.4052 5.94891 26.5273C4.75683 23.6493 4.44492 20.4825 5.05264 17.4273C5.66036 14.3721 7.1604 11.5657 9.36308 9.36307C11.5658 7.16039 14.3721 5.66035 17.4273 5.05263C20.4825 4.44491 23.6493 4.75682 26.5273 5.9489C29.4052 7.14098 31.865 9.15969 33.5957 11.7498C35.3263 14.3398 36.25 17.3849 36.25 20.5C36.2453 24.6757 34.5844 28.679 31.6317 31.6317C28.679 34.5843 24.6757 36.2452 20.5 36.25ZM22.75 18.25V30.4C22.75 30.9967 22.513 31.569 22.091 31.991C21.669 32.4129 21.0967 32.65 20.5 32.65C19.9033 32.65 19.331 32.4129 18.909 31.991C18.4871 31.569 18.25 30.9967 18.25 30.4V18.25C18.25 17.6533 18.4871 17.081 18.909 16.659C19.331 16.2371 19.9033 16 20.5 16C21.0967 16 21.669 16.2371 22.091 16.659C22.513 17.081 22.75 17.6533 22.75 18.25ZM22.75 10.6C22.75 11.045 22.6181 11.48 22.3708 11.8501C22.1236 12.2201 21.7722 12.5085 21.361 12.6788C20.9499 12.8491 20.4975 12.8936 20.0611 12.8068C19.6246 12.72 19.2237 12.5057 18.909 12.191C18.5944 11.8763 18.3801 11.4754 18.2932 11.039C18.2064 10.6025 18.251 10.1501 18.4213 9.73899C18.5916 9.32786 18.88 8.97645 19.25 8.72922C19.62 8.48199 20.055 8.35003 20.5 8.35003C21.0967 8.35003 21.669 8.58708 22.091 9.00904C22.513 9.43099 22.75 10.0033 22.75 10.6Z'
                fill='white'
            />
        </svg>
    );
};

export default Info;
