import clsx from 'clsx';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Countdown, ControlButton } from '../reusable';
import { currentAvailableInfo, formatterInt } from '../../helpers/functions';
import { SALE_BOARD_STATUS, SALE_STATUS } from '../../config/constants';
import { ExpandArrow } from '../../assets/icons';
import { OtarisLogo } from '../../assets/logos';
import { useSaleContext } from '../../context/sale/SaleState';
import { useGlobalContext } from '../../context/global/GlobalState';

const WhitelistBoardPublic = () => {
    const { personalInfo, tiersInfo } = useGlobalContext();
    const { salePublic, getCurrentSaleStatus } = useSaleContext();
    const [collapsed, setCollapsed] = useState(false);
    const isMounted = useRef(true);
    const labelColor = useMemo(() => {
        const status = getCurrentSaleStatus();
        switch (status) {
            case SALE_STATUS.New:
                return SALE_BOARD_STATUS.Upcoming;
            case SALE_STATUS.Open:
                return SALE_BOARD_STATUS.Ongoing;
            default:
                return SALE_BOARD_STATUS.Completed;
        }
    }, [getCurrentSaleStatus]);

    useEffect(() => {
        isMounted.current && setCollapsed(labelColor !== SALE_BOARD_STATUS.Ongoing);
        // eslint-disable-next-line
    }, [salePublic]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClick = () => {
        if (labelColor === SALE_BOARD_STATUS.Ongoing || labelColor === 'Closed' || labelColor === undefined) return;
        setCollapsed(!collapsed);
    };

    return (
        <SkeletonTheme baseColor="#070D1D22" highlightColor="#070D1D80">
            {getCurrentSaleStatus() >= SALE_STATUS.New ? (
                <div className={clsx('whitelist-public-wrapper', collapsed ? 'collapsed' : '')}>
                    <div className={clsx('left-panel', `${labelColor}`)}>
                        <div className={clsx('title-container', `${labelColor}`)} onClick={handleClick}>
                            <div className="title">
                                <span className="name">Stage:</span>
                                <span className="state"> Whitelisting</span>
                            </div>
                            <div className={'state-group'}>
                                <span className={clsx('title-state', `${labelColor}`)}>{labelColor}</span>
                                <div
                                    className="expand-button"
                                    style={{ transform: `rotate(${collapsed ? 0 : 180}deg)` }}
                                >
                                    {labelColor !== undefined && labelColor !== SALE_BOARD_STATUS.Ongoing && (
                                        <ExpandArrow
                                            fill={labelColor === SALE_BOARD_STATUS.Upcoming ? '#bac6d2' : '#11a3b7'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={clsx('collapse-area', collapsed ? 'collapsed' : '')}>
                            <div className="panel-content">
                                <div className="item-container public">
                                    {tiersInfo
                                        ? tiersInfo.slice(1).map((item, index) => (
                                              <div
                                                  className={clsx(
                                                      'item',
                                                      personalInfo.membershipStatus === item.name && 'active'
                                                  )}
                                                  key={index}
                                              >
                                                  <div className="item-img">
                                                      <img
                                                          className={`${item.isExists ? '' : 'image-grayscale'}`}
                                                          src={item.logo}
                                                          alt={item.name}
                                                      />
                                                      <span>{`${item.name}${
                                                          personalInfo.membershipStatus === item.name
                                                              ? salePublic.isApplied
                                                                  ? '(Applied)'
                                                                  : '(You)'
                                                              : ''
                                                      }`}</span>
                                                  </div>
                                                  <div className="item-value">
                                                      {item.isExists ? (
                                                          <>
                                                              {labelColor !== SALE_BOARD_STATUS.Upcoming && (
                                                                  <span>
                                                                      {formatterInt.format(
                                                                          currentAvailableInfo(
                                                                              item.id,
                                                                              salePublic.tiers
                                                                          ).applied
                                                                      )}{' '}
                                                                      Whitelists
                                                                  </span>
                                                              )}
                                                              <span>
                                                                  {formatterInt.format(
                                                                      currentAvailableInfo(item.id, salePublic.tiers)
                                                                          .spots
                                                                  )}{' '}
                                                                  Available
                                                              </span>
                                                          </>
                                                      ) : (
                                                          <span>Not available for this sale</span>
                                                      )}
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </div>
                                {/* )}
                                {saleType === 2 && (
                                    <div className="item-container exclusive">
                                        {MemberShipTiers.slice()
                                            .reverse()
                                            .map(
                                                (item, index) =>
                                                    index > 1 && (
                                                        <div className="item" key={index}>
                                                            <div className="item-img">
                                                                <img src={item.img} alt={item.title} />
                                                                <span>{item.title}</span>
                                                            </div>
                                                            <div className="item-value">
                                                                <span>
                                                                    {formatterInt.format(whitelistValue[index].whitelist)}{' '}
                                                                    Whitelists
                                                                </span>
                                                                <span>
                                                                    {formatterInt.format(whitelistValue[index].available)} Available
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                            )}
                                    </div>
                                )} */}
                            </div>
                            {labelColor !== SALE_BOARD_STATUS.Completed && (
                                <div className="panel-bottom">
                                    <ControlButton style={{ width: '40%' }} />
                                    <div className="logo-back">
                                        <OtarisLogo width={50} height={50} />
                                    </div>
                                    <Countdown style={{ width: '40%' }} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="right-panel">
                        <div className={clsx('title', `${labelColor}`)} onClick={handleClick}>
                            <span>Stage Info</span>
                        </div>
                        <div className={clsx('collapse-area panel-content', collapsed ? 'collapsed' : '')}>
                            <span>
                                All users have to apply to the whitelist in order to participate in the sale. To be able
                                to apply to the whitelist, you have to have a Veriified KYC status. Citizens and
                                Officers will participate in the lottery to get whitelisted. Ambassadors and Senators
                                have a guaranteed whitelist spot but they still need to apply.
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="skeleton-container">
                    <Skeleton height={92} borderRadius={20} />
                </div>
            )}
        </SkeletonTheme>
    );
};

export default WhitelistBoardPublic;
