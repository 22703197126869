import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { SimpleButton, CustomInput } from '../reusable';
import { Check, Cross } from '../../assets/icons';
import { useModalContext } from '../../context/modal/ModalState';
import LMPoolABI from '../../config/abi/LMPool.json';
import config from '../../config/config';
import { Contract, constants, utils } from 'ethers';
import { useGlobalContext } from '../../context/global/GlobalState';
import { toast } from 'react-hot-toast';
import { useUserBalance } from '../../hooks';
import { calculateMargin } from '../../web3/utils';
import { Loading3Dot } from '../../assets/loading';

const Stake = ({ props }) => {
    const { modal, modalData, closeModal, openModal, setModalData } = useModalContext();
    const { activeProvider, account } = useGlobalContext();
    const [stakeLoading, setStakeLoading] = useState(false);
    const [unstakeLoading, setUnstakeLoading] = useState(false);
    const [amount, setAmount] = useState('0');
    const onClose = () => {
        closeModal('stake');
    };
    const userBalance = useUserBalance(
        modalData?.baseTokenAddress,
        account,
        activeProvider,
        modalData?.baseTokenDecimals
    );
    const [claimRewardsCheck, setClaimRewardsCheck] = useState(false);

    const liquidityHandler = (e) => {
        onClose();
        if (modalData?.isStake) {
            setModalData({
                isStake: modalData.isStake,
                token1: 'USDC',
                token2: 'OTA',
                bal1: 4220,
                bal2: 12,
                bal1usd: 4220,
                bal2usd: 150,
                isAdd: false,
            });
            openModal('procSwap');
        } else {
            setModalData({
                isStake: modalData.isStake,
                token1: 'USDC',
                token2: 'OTA',
                bal1: 4220,
                bal2: 12,
                bal1usd: 4220,
                bal2usd: 150,
                isAdd: true,
            });
            openModal('procLiquidity');
        }
    };
    const stakeHandler = async () => {
        toast.promise(
            new Promise(async (res, rej) => {
                try {
                    if (!modalData.lmPoolAddress) throw new Error('Contract is not deployed yet');
                    setStakeLoading(true);

                    const erc20Contract = new Contract(
                        modalData.baseTokenAddress,
                        config.abis.erc20,
                        activeProvider.getSigner()
                    );

                    await erc20Contract.allowance(account, modalData.lmPoolAddress);

                    const estimatedGas = await erc20Contract.estimateGas.approve(
                        modalData.lmPoolAddress,
                        constants.MaxUint256
                    );

                    const approve = await erc20Contract.approve(
                        modalData.lmPoolAddress,
                        utils.parseUnits(amount, modalData.baseTokenDecimals),
                        {
                            gasLimit: calculateMargin(estimatedGas),
                        }
                    );

                    await approve.wait();

                    const lmPoolContract = new Contract(modalData.lmPoolAddress, LMPoolABI, activeProvider.getSigner());

                    const take = await lmPoolContract.stake(utils.parseUnits(amount, modalData.baseTokenDecimals));
                    const receipt = await take.wait();
                    res(receipt);
                    setModalData({
                        status: 'success',
                        tx: receipt.transactionHash,
                        title: `Staked successfully`,
                    });
                } catch (e) {
                    rej(e.message);
                    setModalData({ status: 'fail', title: 'Staking failed' });
                } finally {
                    closeModal('stake');
                    openModal('transactionStatus');
                    setStakeLoading(false);
                }
            }),
            {
                loading: 'Staking in progress...',
                error: (e) => 'Error: ' + e,
                success: (res) => 'Staked successfully:' + res,
            }
        );
    };

    const unstakeHandler = async () => {
        toast.promise(
            new Promise(async (res, rej) => {
                try {
                    if (!modalData.lmPoolAddress) throw new Error('Contract is not deployed yet');
                    setUnstakeLoading(true);
                    const lmPoolContract = new Contract(modalData.lmPoolAddress, LMPoolABI, activeProvider.getSigner());

                    const take = await lmPoolContract.unstake(
                        utils.parseUnits(amount, modalData.baseTokenDecimals),
                        claimRewardsCheck
                    );
                    const receipt = await take.wait();
                    res(receipt);
                    setModalData({
                        status: 'success',
                        tx: receipt.transactionHash,
                        title: `Unstaked successfully`,
                    });
                } catch (e) {
                    rej(e.message);
                    setModalData({ status: 'fail', title: 'Unstaking failed' });
                } finally {
                    closeModal('stake');
                    openModal('transactionStatus');
                    setUnstakeLoading(false);
                }
            }),
            {
                loading: 'Unstaking in progress...',
                error: (e) => 'Error: ' + e,
                success: (res) => 'Unstaked successfully:' + res,
            }
        );
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.stake}
            overlayClassName="modal-overlay"
            className="modal-content stake-modal"
            // shouldCloseOnOverlayClick={true}
            // onRequestClose={onClose}
        >
            <h2 className="header">Stake/Unstake</h2>
            <button className="close-button" type="button" onClick={onClose}>
                <Cross />
            </button>

            <div className="content">
                <div className="balance-container">
                    <span>
                        Balance: {userBalance} {modalData?.symbol || 'N/A'}
                    </span>
                    <span>
                        Staked Balance: {modalData?.stakedAmount || 0} {modalData?.symbol || 'N/A'}
                    </span>
                </div>

                <CustomInput setValue={setAmount} value={amount} />
                <div
                    onClick={() => setClaimRewardsCheck(!claimRewardsCheck)}
                    style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', cursor: 'pointer' }}
                >
                    <div
                        style={{
                            borderRadius: 8,
                            backgroundColor: 'rgba(255,255,255,0.2)',
                            width: 28,
                            height: 28,
                            marginRight: 8,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {claimRewardsCheck ? <Check /> : null}
                    </div>
                    <p style={{ fontSize: 14 }}>Claim rewards for unstaking</p>
                </div>
                <div className="button-container">
                    <SimpleButton isDisabled={unstakeLoading || stakeLoading} clickHandler={unstakeHandler}>
                        Unstake
                        {unstakeLoading ? (
                            <div className="spinner-wrapper">
                                <Loading3Dot width={32} height={32} />
                            </div>
                        ) : null}
                    </SimpleButton>
                    <SimpleButton
                        isDisabled={unstakeLoading || stakeLoading || modalData?.isEnded}
                        clickHandler={stakeHandler}
                    >
                        Stake
                        {stakeLoading ? (
                            <div className="spinner-wrapper">
                                <Loading3Dot width={32} height={32} />
                            </div>
                        ) : null}
                    </SimpleButton>
                </div>
                <div className="link-container">
                    <span onClick={liquidityHandler}>Provide OTA-ETH Liquidity</span>
                </div>
            </div>
        </ReactModal>
    );
};

export default Stake;
