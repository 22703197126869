import React from 'react';

const InfoMark2 = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#11A3B7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V6.5C9 7.05228 9.44772 7.5 10 7.5C10.5523 7.5 11 7.05228 11 6.5V6ZM11 9.5C11 8.94772 10.5523 8.5 10 8.5C9.44772 8.5 9 8.94772 9 9.5V14.3C9 14.8523 9.44772 15.3 10 15.3C10.5523 15.3 11 14.8523 11 14.3V9.5Z"
                fill="white"
            />
        </svg>
    );
};

export default InfoMark2;
