import React from 'react';

const MemberShip = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M19.7592 2.75936C20.0948 2.32068 20.0916 1.68342 19.7011 1.29289L18.942 0.533781C18.6261 0.217878 18.1411 0.155908 17.73 0.330775C16.7147 0.762649 15.545 1 14.4082 1C13.0082 1 11.5482 0.63 10.4082 0C9.2682 0.63 7.8082 1 6.4082 1C5.27143 1 4.1017 0.762649 3.08637 0.330775C2.67526 0.155908 2.19032 0.217878 1.87442 0.533781L1.11531 1.29289C0.724785 1.68342 0.72165 2.32068 1.05718 2.75936C1.64121 3.52292 2.4082 4.76146 2.4082 6C2.4082 7 1.9082 8.5 1.4082 10C0.908203 11.5 0.408203 13 0.408203 14C0.408203 18 10.4082 20 10.4082 20C10.4082 20 20.4082 18 20.4082 14C20.4082 13 19.9082 11.5 19.4082 10C18.9082 8.5 18.4082 7 18.4082 6C18.4082 4.76146 19.1752 3.52292 19.7592 2.75936ZM13.1347 6.36386C13.1347 7.87009 11.9136 9.09114 10.4074 9.09114C8.90115 9.09114 7.68011 7.87009 7.68011 6.36386C7.68011 4.85763 8.90115 3.63659 10.4074 3.63659C11.9136 3.63659 13.1347 4.85763 13.1347 6.36386ZM9.6236 10.0003C7.54603 10.0003 5.86184 11.6845 5.86184 13.762C5.86184 14.1949 6.21271 14.5457 6.64554 14.5457H14.1691C14.6019 14.5457 14.9527 14.1949 14.9527 13.762C14.9527 11.6845 13.2686 10.0003 11.191 10.0003H9.6236Z" fill="white"/>
        </svg>

    );
};

export default MemberShip;
