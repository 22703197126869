import clsx from 'clsx';
import camelcaseKeys from 'camelcase-keys';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { fetchWrapper } from '../../helpers/fetch-wrapper';
import { convertRes2KYCStatus, formatterFloat, getCookie, getStatusWithTierId } from '../../helpers/functions';
import { useUserBalance } from '../../hooks';
import { KYC_STATUS, MEMBERSHIP_STATUS } from '../../config/constants';
import Bookmark from '../../assets/svg/bookmark.svg';
import { useGlobalContext } from '../../context/global/GlobalState';
import { useModalContext } from '../../context/modal/ModalState';

const WalletStatusButton = () => {
    const { openModal } = useModalContext();
    const {
        account,
        activeProvider,
        activateWallet,
        jwtToken,
        setJwtToken,
        setKYCEmail,
        setKYCStatus,
        setKYCInfo,
        isValidWallet,
        setWalletStatus,
        logout,
        disconnectWallet,
        publicInfo,
        otaBalance,
        setOTABalance,
        tiersInfo,
        personalInfo,
        setPersonalInfo,
        setCurrentTier,
        currentTier,
        privateSocket,
        setSalesList,
        setSaleStatus,
        authReCaptchaRef,
    } = useGlobalContext();
    const _userBalance = useUserBalance(publicInfo.tokenAddress, account, activeProvider);

    const navigate = useNavigate();
    let location = useLocation();
    const isMounted = useRef(true);

    const handleDisconnect = () => {
        if (account) {
            logout();
            disconnectWallet();
            authReCaptchaRef.current.reset();
            if (location.pathname.startsWith('/sales/') === true) navigate('/sales');
        }
    };

    useEffect(() => {
        if (!_userBalance) return;
        setOTABalance(_userBalance);
        // eslint-disable-next-line
    }, [_userBalance]);

    const authenticateSocket = async (socketId) => {
        await fetchWrapper
            .post(`/api/User/AuthenticateSocket/${socketId}`, {})
            .then(async (res) => {
                console.log('User authenticated for the private socket.');
            })
            .catch((res) => {
                console.log('Socket authentication failed.');
                toast.error('Websocket authorization failed.');
                handleDisconnect();
            });
    };

    useEffect(() => {
        const getKYCStatus = async () => {
            let kycRes = await fetchWrapper
                .get('/api/User/KYC/Status')
                .then((res) => res)
                .catch((res) => {
                    console.log('KYC/Status', res.error);
                });
            if (!kycRes) return false;

            kycRes = convertRes2KYCStatus(kycRes);

            // kycRes = KYC_STATUS.VERIFYING; // _test_code
            setKYCStatus(kycRes);

            let result = {};
            const userInfo = await fetchWrapper.get('/api/User/Info').catch((res) => {
                console.log('Error from /api/User/Info', res.error);
            });
            if (userInfo) {
                result.email = userInfo.email ?? '';
                result.telegramUsername = userInfo.telegramUsername ?? '';
                result.twitterUsername = userInfo.twitterUsername ?? '';

                const memberShipStatus = getStatusWithTierId(userInfo.membershipTierId, tiersInfo);
                setCurrentTier(memberShipStatus);
                setPersonalInfo({ membershipNftId: userInfo.membershipTierTokenId });
            }

            if (kycRes === KYC_STATUS.VERIFIED) return;

            if (kycRes === KYC_STATUS.UNVERIFIED) {
                setKYCInfo(result);
                return;
            }

            // get kyc data for failed or verifying status
            const kycData = await fetchWrapper.get('/api/User/KYC/Data').catch((res) => {
                console.log('Error from /api/user/KYC/Data', res.error);
            });
            if (kycData) {
                result = { ...result, ...kycData };
            }

            setKYCInfo(result);

            return true;
        };

        const onWalletConnect = async () => {
            if (jwtToken) {
                handleDisconnect();
                return;
            }

            let hasCookie = getCookie('HasAuthCookie') === 'True';
            if (hasCookie) {
                const prevAddress = localStorage.getItem('prevAddress');
                hasCookie = !prevAddress ? false : prevAddress.toLowerCase() === account.toLowerCase();
            }

            if (!hasCookie) {
                try {
                    const nonce = await fetchWrapper.post('/api/User/Nonce/' + account.toLowerCase()).catch((e) => {
                        console.log('Error from /api/user/Nonce', e);
                        return null;
                    });
                    if (!nonce) {
                        handleDisconnect();
                        return;
                    }

                    const signature = await window.ethereum.request({
                        method: 'personal_sign',
                        params: [nonce, account, 'Example password'],
                    });
                    let tokenForAuth;
                    const handleReCaptchaClose = () => {
                        const recaptchaWindow = [...document.getElementsByTagName('iframe')]?.find((x) =>
                            x.src.includes('google.com/recaptcha/api2/bframe')
                        )?.parentNode?.parentNode;

                        if (recaptchaWindow) {
                            new MutationObserver(() => {
                                if (
                                    !tokenForAuth &&
                                    (recaptchaWindow.style.visibility !== 'visible' ||
                                        recaptchaWindow.style.opacity !== '1' ||
                                        recaptchaWindow.style.top !== '10px')
                                ) {
                                    toast.error('ReCaptcha challange canceled, try again and complete the challange.');
                                    handleDisconnect();
                                }
                            }).observe(recaptchaWindow, {
                                attributeFilter: ['style'],
                            });
                        }
                    };
                    handleReCaptchaClose();
                    tokenForAuth = await authReCaptchaRef.current.executeAsync();

                    await fetchWrapper
                        .postWithReCaptcha(
                            '/api/User/Authenticate',
                            {
                                walletAddress: account.toLowerCase(),
                                signedMessage: signature,
                            },
                            tokenForAuth
                        )
                        .then((ret) => {
                            hasCookie = getCookie('HasAuthCookie') === 'True';
                            localStorage.setItem('prevAddress', ret);
                        })
                        .catch((e) => {
                            hasCookie = false;
                        });
                } catch (e) {
                    toast.error('Signature request denied, please try to connect your wallet again.');
                    handleDisconnect();
                    hasCookie = false;
                }
            }

            if (hasCookie) {
                getKYCStatus();
                isMounted.current && setJwtToken(true);
                isMounted.current && setWalletStatus(true);
                privateSocket.current.connect();
            }
        };

        if (account && tiersInfo) {
            onWalletConnect();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, tiersInfo]);

    useEffect(() => {
        if (!isMounted.current) return;

        privateSocket.current.on('connect', () => {
            console.log('Private Socket connected');

            privateSocket.current.emit('GetInfo');
        });

        privateSocket.current.on('disconnect', (reason) => {
            console.log('Private Socket disconnected: reason = ', reason);

            if (reason === 'io server disconnect') privateSocket.current.connect();
        });

        privateSocket.current.on('reconnect', () => {
            console.log('Private Socket reconnecting');
        });

        privateSocket.current.on('SocketInfo', (data) => {
            console.log('SocketInfo', data);
            data = camelcaseKeys(data, { deep: true });
            if (data?.status === 'Authorized') return;

            authenticateSocket(data.id);
        });

        privateSocket.current.on('Info', (data) => {
            console.log('Info', data);
            data = camelcaseKeys(data, { deep: true });
            if (data?.isEmailVerified) {
                setKYCEmail();
            }

            if (data?.kycState) setKYCStatus(convertRes2KYCStatus(data.kycState));
            if (data?.membershipTierTokenId) setPersonalInfo({ membershipNftId: data.membershipTierTokenId });
            console.log('privateSocket DATAa', data);
            const memberShipStatus = getStatusWithTierId(data.membershipTierId, tiersInfo);
            setCurrentTier(memberShipStatus);
        });

        privateSocket.current.on('List', (data) => {
            console.log('Private socket List', data);
            data = camelcaseKeys(data, { deep: true });
            setSalesList(setSaleStatus(data), 'Private');
        });

        const hasCookie = getCookie('HasAuthCookie') === 'True';
        if (hasCookie && localStorage.getItem('prevAddress')) {
            let prevConnector = localStorage.getItem('prevConnector');
            if (!prevConnector) prevConnector = 'MetaMask';

            activateWallet(prevConnector);
            // } else {
            //     navigate('/');
        }

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className={`wallet-status-wrapper ${isValidWallet && account ? '' : 'not-'}connected`}>
                <button
                    className={clsx('btn-wrapper', 'simple-btn-wrapper')}
                    onClick={() => {
                        if (!isValidWallet || !account) openModal('connectWallet');
                        else handleDisconnect();
                    }}
                >
                    {!account || !isValidWallet || personalInfo.membershipStatus === MEMBERSHIP_STATUS.Visitor ? (
                        <img src={Bookmark} alt="Bookmark" />
                    ) : (
                        <img src={currentTier.current.logo} alt={currentTier.current.name} />
                    )}

                    {!isValidWallet || !account ? 'Connect Wallet' : `${account.slice(0, 8)}...${account.slice(-4)}`}
                </button>
                {account && isValidWallet && publicInfo.tokenAddress && (
                    <span className="wallet-info">
                        {formatterFloat.format(otaBalance)} {publicInfo.tickerSymbol}
                    </span>
                )}
            </div>
        </>
    );
};

export default WalletStatusButton;
